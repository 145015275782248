/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * Login form Component, including the logo.
 */

import { IonCol, IonGrid, IonImg, IonItem, IonLabel, IonList, IonRow, IonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { analytics } from '../../config/firebaseConfig';
import { z } from 'zod';

import logo from '../../assets/oryx-logo.svg';
import { useAuthContext } from '../authContext';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { Button } from '../Button';
import { Input } from '../Input';
import { mergeClassNames } from '../../utilities/mergeClassNames';
import { IonInputCustomEvent } from '@ionic/core';

import './loginForm.css';
import styles from './loginForm.module.css';
import { Toast } from '../Toast/Toast';

export const ResetPasswordForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSend, setIsEmailSend] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const { resetPassword, state, clearError } = useAuthContext();

  useEffect(() => {
    if (state.error) {
      if (import.meta.env.MODE !== 'production') console.log(state.error);
    }
  }, [state]);

  async function requestReset(e: React.FormEvent) {
    analytics.logEvent('resetPassword');
    e.preventDefault();
    setFormSubmitted(true);

    const emailValidationSchema = z.string().email();

    const result = emailValidationSchema.safeParse(email);

    setIsValidEmail(result.success);

    if (email && result.success) {
      setIsLoading(true);
      await resetPassword(email);
      setIsLoading(false);
      setIsEmailSend(true);

      // Ensures that if user submits the form again, the toast is dismissed
      setTimeout(() => {
        setIsEmailSend(false);
      }, 6_000);
    }
  }

  const handleEmail = (e: any) => {
    setEmail(e.detail.value);
  };

  function handleFocusEvent(ev: IonInputCustomEvent<FocusEvent>) {
    ev.target.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <>
      <Toast
        isOpen={!isValidEmail}
        header='Email address is invalid'
        message={'The email address you entered is not valid. Please enter a valid email address.'}
        type='warning'
      />

      <Toast
        type='info'
        isOpen={isEmailSend}
        header='An email has been sent'
        message='Check your inbox to reset your password'
      />

      <form noValidate onSubmit={requestReset} className={styles['login-form']}>
        <IonGrid fixed className={styles['no-padding']} id='login-form'>
          <div>
            {import.meta.env.MODE !== 'production' && (
              <>
                <IonRow className={mergeClassNames('warning', styles.dev)}>
                  <IonCol>
                    <IonText style={{ color: '#ffffff' }}>{import.meta.env.MODE} environment!</IonText>
                  </IonCol>
                </IonRow>
              </>
            )}

            <IonRow>
              <IonCol size='12' className={styles['no-padding']}>
                <IonImg src={logo} className={styles.logo}></IonImg>
              </IonCol>
            </IonRow>
          </div>
          <div id='focus-target' className={styles.main}>
            <IonRow>
              <IonCol size='12' className={styles.copy}>
                <IonText className={styles.heading}>Reset your password</IonText>
                <IonText className={styles.description}>Enter your user account&apos;s verified email address.</IonText>
              </IonCol>
            </IonRow>

            <IonRow className={styles['no-padding']}>
              <IonCol size='12' class={styles['no-padding']}>
                <IonList mode='md' class={mergeClassNames(styles['no-padding'], styles.list)}>
                  <Input
                    name='email'
                    type='email'
                    label='Email'
                    label-placement='stacked'
                    onIonInput={handleEmail}
                    hasError={!isValidEmail}
                    onIonFocus={handleFocusEvent}
                  />
                </IonList>
              </IonCol>
            </IonRow>
            <IonRow class={styles['no-padding']}>
              <IonCol size='12' class={mergeClassNames(styles['button-wrapper'], styles['no-padding'])}>
                <Button type='submit' isFullWidth variant='primary' className={styles.button} isLoading={isLoading}>
                  Reset password
                </Button>
              </IonCol>
            </IonRow>
          </div>
        </IonGrid>
      </form>
    </>
  );
};

import { IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import IBimagePelvicobliquity1 from './assets/IBimagePelvicobliquity1.png';
import IBimagePelvicobliquity2 from './assets/IBimagePelvicobliquity2.png';

// Slides
import { SwiperSlide } from 'swiper/react';
import { VerticalSwiper } from '../../../../components/swiper';

// Styles
import styles from './ModalContent.module.css';
export const PelvicObliquityModalContent = () => {
  return (
    <>
      <VerticalSwiper>
        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '100%' }}>
              <IonCol size='12' size-lg='5' size-xl='5' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimagePelvicobliquity1} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='12' size-lg='6' size-xl='6'>
                <div>
                  At initial contact the position of it is almost neutral (0° obliquity). Throughout loading response,
                  the hip of the trailing leg begins to drop, increasing the magnitude of pelvic obliquity. Hip
                  abductors become active to halt the contralateral pelvic drop.
                  <br></br>
                  <br></br>
                  After midstance, the motion of the pelvis is reversed, with the swing-side hip lifting to regain
                  neutral position again. This movement is called ‘hike’. People with a normal healthy gait, typically
                  exhibit 5° to 7° of pelvic obliquity towards each side. The magnitude of pelvic obliquity increases
                  linearly with walking speed.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow style={{ height: '100%' }} className='ion-align-items-center'>
              <div>
                <IonRow class='ion-justify-content-center' style={{ height: '50%', width: '100%' }}>
                  <IonCol size='11' className='ion-no-padding'>
                    <div>
                      <IonImg src={IBimagePelvicobliquity2} class={styles.avatar} />
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size='11'>
                    <div>
                      Since the weight of the upper body acts downward through the pelvis, control of pelvic motion is
                      vital to maintaining whole body balance in the coronal plane.
                      <br></br>
                      <br></br>
                      Excessive pelvic drop (Trendelenburg sign) is due to weakness of the hip abductors on the
                      supporting leg side.
                    </div>
                  </IonCol>
                </IonRow>
              </div>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </VerticalSwiper>
    </>
  );
};

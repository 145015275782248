/** Main url routes for the application */
import { IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { GuardedRoute } from './components/authContext/GuardedRoute';
import { GuestRoute } from './components/authContext/GuestRoute';
import { LoginPage, NewPasswordPage, ResetPasswordPage } from './pages/Login/Login';

import { ReportsPage } from './pages/ReportList';
import { UploadPage } from './pages/Upload/Upload';
import { ReportProvider } from './components/reportContext';
import { Dashboard } from './pages/Dashboard';
import { MeasurementsPage } from './pages/Measurements';
import { ORYXTestPage } from './pages/Measurements/ORYX/ORYX';
import { HardwareProvider } from './components/hardwareContext';
import { useAuthContext } from './components/authContext';
import { SelectNetworkPage } from './pages/Measurements/ORYX/selectNetwork';
import { UserInfoProvider } from './components/userInfoContext';
import { AdminPage } from './pages/Admin';
import { Redirect, Route } from 'react-router';
import { DeviceManagementPage } from './pages/Device Management';
import { KBHomePage } from './pages/Knowlegde Base';
import { WalkMeasurementDoc } from './pages/Knowlegde Base/ORYX_Measurements/Walk';
import { KneeStabilityTestPage } from './pages/Measurements/ORYXKneeStability/KneeStabilityTestPage';
import { WalkReportDetailPage } from './pages/ReportV2/WalkReportV2';
import { RunReportDetailPageV2 } from './pages/ReportV2/RunReportV2';
import { SingleLegSquatReportDetailPageV2 } from './pages/ReportV2/SingleLegSquatsReportV2';
import { SquatReportDetailPageV2 } from './pages/ReportV2/SquatsReportV2';
import { PitchingDashboard } from './pages/PitchingAnalysis';
import { Sidebar } from './components/Sidebar/Sidebar';
import { SensorTestingPage } from './pages/Measurements/SensorTesting';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
/** Main url routes for the application
 * FirebaseAuthConsumer provides the userId parameter to the sub pages when a user is logged in.
 * If you are not logged in only the home and login page is rendered.
 */
export const Routes = () => {
  const { isAuthorized } = useAuthContext();
  return (
    <IonReactRouter>
      {!isAuthorized() ? (
        <IonRouterOutlet>
          <GuestRoute path='/'>
            <LoginPage />
          </GuestRoute>
          <GuestRoute path='/login'>
            <LoginPage />
          </GuestRoute>
          <GuestRoute path='/resetPassword'>
            <ResetPasswordPage />
          </GuestRoute>
          <GuestRoute path='/newPassword'>
            <NewPasswordPage />
          </GuestRoute>
        </IonRouterOutlet>
      ) : (
        <IonSplitPane contentId='main' when='xs'>
          <UserInfoProvider>
            <Sidebar />

            <IonRouterOutlet id='main'>
              <ReportProvider>
                <ErrorBoundary>
                  <GuardedRoute path='/'>
                    <Redirect to='/dashboard' />
                  </GuardedRoute>
                  <GuardedRoute path='/dashboard'>
                    <Dashboard />
                  </GuardedRoute>
                  <GuardedRoute path='/admin'>
                    <AdminPage />
                  </GuardedRoute>
                  <HardwareProvider>
                    {/* Pitching analysis */}
                    <GuardedRoute path='/pitching'>
                      <PitchingDashboard />
                    </GuardedRoute>

                    <GuardedRoute path='/devicemanagement'>
                      <DeviceManagementPage />
                    </GuardedRoute>
                    <GuardedRoute path='/sensortesting'>
                      <SensorTestingPage />
                    </GuardedRoute>
                    <GuardedRoute path='/measurements'>
                      <MeasurementsPage />
                    </GuardedRoute>
                    <GuardedRoute path='/ORYX'>
                      <ORYXTestPage />
                    </GuardedRoute>
                    <GuardedRoute path='/measurements/KneeStability/:hardwareId'>
                      <KneeStabilityTestPage />
                    </GuardedRoute>
                    <GuardedRoute path='/measurements/ORYX/selectNetwork'>
                      <SelectNetworkPage />
                    </GuardedRoute>
                  </HardwareProvider>
                  <GuardedRoute path='/reports'>
                    <ReportsPage />
                  </GuardedRoute>
                  <GuardedRoute path='/reportsV2/Walk/:id'>
                    <WalkReportDetailPage />
                  </GuardedRoute>
                  <GuardedRoute path='/reportsV2/Run/:id'>
                    <RunReportDetailPageV2 />
                  </GuardedRoute>
                  <GuardedRoute path='/reportsV2/Squat/:id'>
                    <SquatReportDetailPageV2 />
                  </GuardedRoute>
                  <GuardedRoute path='/reportsV2/SingleLegSquat/:id'>
                    <SingleLegSquatReportDetailPageV2 />
                  </GuardedRoute>
                  <GuardedRoute path='/upload'>
                    <UploadPage />
                  </GuardedRoute>

                  <GuardedRoute path='/docs'>
                    <KBHomePage />
                  </GuardedRoute>
                  <GuardedRoute path='/docs/ORYX_Measurements/Walk'>
                    <WalkMeasurementDoc />
                  </GuardedRoute>
                  <Route render={() => <Redirect to={'./dashboard'} />} />
                </ErrorBoundary>
              </ReportProvider>
            </IonRouterOutlet>
          </UserInfoProvider>
        </IonSplitPane>
      )}
    </IonReactRouter>
  );
};

import { IonCol, IonImg, IonList, IonRow } from '@ionic/react';

// Context
import { useAuthContext } from '../authContext';
import { useUserInfoContext } from '../userInfoContext';

// Components
import { Input } from '../Input';

// Styles
import styles from './ProfileModalContent.module.css';
import { mergeClassNames } from '../../utilities/mergeClassNames';

interface ProfileModalProps {
  cleanUp?: () => void;
}

export const ProfileModalContent = ({ cleanUp }: ProfileModalProps) => {
  const user = useAuthContext().state.userState?.user;
  const { userInfo } = useUserInfoContext();

  const imgURL = new URL(`../../assets/clientProfileLogos/${userInfo.organization.logoURL}.png`, import.meta.url).href;

  return (
    <div className={styles.wrapper}>
      <IonRow className='ion-align-items-center ion-justify-content-center'>
        <IonCol size='6' className='ion-justify-content-center ion-text-center '>
          <IonImg
            className=''
            src={imgURL}
            alt='Organization Logo'
            style={{ width: '75%', marginLeft: 'auto', marginRight: 'auto' }}
          ></IonImg>
        </IonCol>
      </IonRow>
      <IonRow className='ion-align-items-center ion-justify-content-center' style={{ width: '100%' }}>
        <IonCol size='12' className='ion-margin-top'>
          <IonList mode='md' class={mergeClassNames(styles['no-padding'], styles.list)}>
            <Input
              readonly={true}
              label='Name'
              disabled
              labelPlacement='stacked'
              value={`${userInfo.firstName} ${userInfo.lastName}`}
              variant='light'
            />
            <br />
            <Input
              disabled
              label='Email'
              label-placement='stacked'
              readonly={true}
              value={user!.email!}
              variant='light'
            />
            <br />
            <Input
              disabled
              label='Organization'
              label-placement='stacked'
              readonly={true}
              value={userInfo.organization.org_name!}
              variant='light'
            />
          </IonList>
        </IonCol>
      </IonRow>
    </div>
  );
};

/** The Run Kinematics report tab */
import { IonCardContent, IonCol, IonFabButton, IonGrid, IonIcon, IonRow, IonText } from '@ionic/react';
import * as React from 'react';

import '../components/Graphs/graphCard.css';

import { RunHamstringFunctionData } from '../../../model/reportV2.model';
import { useEffect, useMemo, useState } from 'react';
import toggle from '../../../assets/icons/toggle-left.svg';
import {
  KneePositionGraph,
  PelvisFunctionGraph,
  ProximodistalSequenceGraph,
  ScissorsColumnChart,
  ThighBarGraph,
  ThighCoordinationGraph,
} from '../components/Graphs';
import {
  InterLimbCoordinationModalContent,
  KneePositionsModalContent,
  PelvisAngularExcursionModalContent,
  ProximodistalSequenceModalContent,
} from '../components/Modals';
import { ReportModal } from '../../../components/Modal/ReportModal';
import { InfoToast } from '../../../components/InfoToast/InfoToast';
import { FloatingButton } from '../../../components/FloatingButton/FloatingButton';
import { IconSize } from '../../../components/Icon/types';
import { Icon } from '../../../components/Icon';
import { Card } from '../../../components/Card';
import { CardHeader } from '../../../components/CardHeader/cardHeader';
import { DataTable } from '../../../components/DataTable/DataTable';
import { DataTableHeader } from '../../../components/DataTable/DataTableHeader';
import { DataTableSection } from '../../../components/DataTable/DataTableSection';
import { DataTableColumn } from '../../../components/DataTable/DataTableColumn';

type RunHamstringFunctionReportTabProps = {
  tabData: RunHamstringFunctionData;
  setFloatingButtons: (buttons: React.ReactNode) => void;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunHamstringFunctionReportTab = ({ tabData, setFloatingButtons }: RunHamstringFunctionReportTabProps) => {
  const [showInfoToast, setShowInfoToast] = useState(false);
  const [showAllStrides, setShowAllStrides] = useState<boolean>(true);

  const toggleShowAllStrides = () => {
    setShowAllStrides((prev) => {
      const newValue = !prev;
      localStorage.setItem('showAllStridesHamstringFunction', newValue ? 'true' : 'false');
      return newValue;
    });
  };

  const seriesPelvisLeft = useMemo(() => {
    return showAllStrides ? tabData.pelvisKinematicData.allStrides.Left : tabData.pelvisKinematicData.mean.Left;
  }, [showAllStrides, tabData]);

  const seriesPelvisRight = useMemo(() => {
    return showAllStrides ? tabData.pelvisKinematicData.allStrides.Right : tabData.pelvisKinematicData.mean.Right;
  }, [showAllStrides, tabData]);

  const seriesKneePositionsLeft = useMemo(() => {
    return showAllStrides ? tabData.kneePositionsData.kneePositionsLeft : tabData.kneePositionsData.kneePositionLeft;
  }, [showAllStrides, tabData]);

  const seriesKneePositionsRight = useMemo(() => {
    return showAllStrides ? tabData.kneePositionsData.kneePositionsRight : tabData.kneePositionsData.kneePositionRight;
  }, [showAllStrides, tabData]);

  const [showZoomModal, setShowZoomModal] = useState({
    show: false,
    graph: '',
  });

  useEffect(() => {
    setShowInfoToast(true);
    return () => {
      setShowInfoToast(false);
    };
  }, []);

  useEffect(() => {
    const buttons = (
      <>
        <IonFabButton onClick={() => toggleShowAllStrides()} title={'Toggle Mean / All Strides'}>
          <IonIcon icon={toggle}></IonIcon>
        </IonFabButton>
        <IonFabButton onClick={() => setShowInfoToast(true)} title={'Show Info'}>
          <Icon name={'info'}></Icon>
        </IonFabButton>
      </>
    );
    setFloatingButtons(buttons); // Update buttons in parent
    return () => setFloatingButtons(null); // Clean up on unmount
  }, [setFloatingButtons]);

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <ReportModal
          isOpen={showZoomModal.show}
          onDidDismiss={() => setShowZoomModal({ show: false, graph: '' })}
          setShowInfo={() => setShowZoomModal({ show: false, graph: '' })}>
          <IonGrid className='' style={{ height: '100%' }}>
            {showZoomModal.graph === 'Pelvis' && (
              <PelvisAngularExcursionModalContent
                pelvisAngularExcursionGraphAnnotations={tabData.pelvisAngularExcursionGraphAnnotations}
                pelvisKinematicData={tabData.pelvisKinematicData}
                setShowZoomModal={setShowZoomModal}
              />
            )}
            {showZoomModal.graph === 'Knee Positions' && (
              <KneePositionsModalContent data={tabData.kneePositionsData} setShowZoomModal={setShowZoomModal} />
            )}
            {showZoomModal.graph === 'Proximodistal Sequence' && (
              <ProximodistalSequenceModalContent
                EuclideanData={tabData.euclideanNormData}
                proximodistalSequenceData={tabData.proximoDistalSequenceAnnotations}
                setShowZoomModal={setShowZoomModal}
                xAxisLeft={tabData.annotations_Left[3]}
                xAxisRight={tabData.annotations_Right[3]}
                cycleEventAnnotations_Left={tabData.thighCoordinationData.Left.annotations}
                cycleEventAnnotations_Right={tabData.thighCoordinationData.Right.annotations}
              />
            )}
            {showZoomModal.graph === 'InterLimb Coordination' && (
              <InterLimbCoordinationModalContent
                crp_Left={tabData.thighCoordinationData.Left.crpData}
                crp_Right={tabData.thighCoordinationData.Right.crpData}
                coordinativeVariabilityData_Left={tabData.thighCoordinationData.Left.coordinativeVariability}
                coordinativeVariabilityData_Right={tabData.thighCoordinationData.Right.coordinativeVariability}
                setShowMoreInfoModal={setShowZoomModal}
                annotations={true}
                graphAnnotations_Left={tabData.thighCoordinationData.Left.annotations}
                graphAnnotations_Right={tabData.thighCoordinationData.Right.annotations}
              />
            )}
          </IonGrid>
        </ReportModal>

        {/* Pelvis Angular Excursion */}
        <IonRow className='ion-no-padding'>
          <IonCol size='12' className=''>
            <Card className='' style={{ margin: 0 }} onClick={() => setShowZoomModal({ show: true, graph: 'Pelvis' })}>
              <CardHeader title={'Pelvis Angular Excursion'}></CardHeader>

              <IonCardContent>
                <IonRow>
                  <IonCol size='6' className='ion-no-padding'>
                    <PelvisFunctionGraph
                      data={seriesPelvisLeft.series}
                      title={'Left'}
                      subtitle={'Anterior tilt / Drop (-) | Posterior tilt / Hike (+)'}
                      graphAnnotations={tabData.pelvisAngularExcursionGraphAnnotations.Left}
                      strokeWidth={showAllStrides ? 1 : 2}
                      yAxis={seriesPelvisLeft.yAxis}
                    />
                  </IonCol>
                  <IonCol size='6' className='ion-no-padding'>
                    <PelvisFunctionGraph
                      data={seriesPelvisRight.series}
                      title={'Right'}
                      subtitle={'Anterior tilt / Drop (-) | Posterior tilt / Hike (+)'}
                      graphAnnotations={tabData.pelvisAngularExcursionGraphAnnotations.Right}
                      strokeWidth={showAllStrides ? 1 : 2}
                      yAxis={seriesPelvisRight.yAxis}
                    />
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </Card>
          </IonCol>
        </IonRow>
        {/* Thigh / Thigh Coordination */}
        <IonRow className='ion-no-padding'>
          <IonCol size='12' className=''>
            <Card
              className=''
              style={{ margin: 0 }}
              onClick={() => setShowZoomModal({ show: true, graph: 'InterLimb Coordination' })}>
              <CardHeader title={'Inter-Thigh Coordination'}></CardHeader>

              <IonCardContent>
                <IonRow>
                  <IonCol size='6' className='ion-no-padding'>
                    <ThighCoordinationGraph
                      crpData={tabData.thighCoordinationData.Left.crpData}
                      coordinativeVariabilityData={tabData.thighCoordinationData.Left.coordinativeVariability}
                      graphAnnotations={tabData.thighCoordinationData.Left.annotations}
                      strokeWidth={2}
                      title={'Left'}
                    />
                  </IonCol>
                  <IonCol size='6' className='ion-no-padding'>
                    <ThighCoordinationGraph
                      crpData={tabData.thighCoordinationData.Right.crpData}
                      coordinativeVariabilityData={tabData.thighCoordinationData.Right.coordinativeVariability}
                      graphAnnotations={tabData.thighCoordinationData.Right.annotations}
                      strokeWidth={2}
                      title={'Right'}
                    />
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </Card>
          </IonCol>
        </IonRow>
        {/* Knee Positions */}
        <IonRow className='ion-no-padding'>
          <IonCol size='12' className=''>
            <Card
              className=''
              style={{ margin: 0 }}
              onClick={() => setShowZoomModal({ show: true, graph: 'Knee Positions' })}>
              <CardHeader title={'Knee Positions'}></CardHeader>

              <IonCardContent>
                <IonRow>
                  <IonCol size='6' className='ion-no-padding'>
                    <KneePositionGraph
                      data={seriesKneePositionsLeft}
                      title={'Left'}
                      subtitle={`Max Knee Height: ${tabData.kneePositionsData.maxKneePositions.col0[0].toFixed(2) + ' cm'}`}
                      strokeWidth={showAllStrides ? 1 : 2}
                    />
                  </IonCol>
                  <IonCol size='6' className='ion-no-padding'>
                    <KneePositionGraph
                      data={seriesKneePositionsRight}
                      title={'Right'}
                      subtitle={`Max Knee Height: ${tabData.kneePositionsData.maxKneePositions.col1[0].toFixed(2) + ' cm'}`}
                      strokeWidth={showAllStrides ? 1 : 2}
                    />
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </Card>
          </IonCol>
        </IonRow>
        {/* Proximodistal Sequence */}
        <IonRow className='ion-no-padding'>
          <IonCol size='12' className=''>
            <Card
              className=''
              style={{ margin: 0 }}
              onClick={() => setShowZoomModal({ show: true, graph: 'Proximodistal Sequence' })}>
              <CardHeader title={'Proximodistal Sequence'}></CardHeader>

              <IonCardContent>
                <IonRow>
                  <IonCol size='6' className='ion-no-padding'>
                    <ProximodistalSequenceGraph
                      data={tabData.euclideanNormData.mean.Left.series}
                      title={'Left'}
                      graphAnnotations={tabData.proximoDistalSequenceAnnotations.Left}
                      yAxis={tabData.euclideanNormData.mean.Left.yAxis}
                      xAxisStart={tabData.annotations_Left[3]}
                      cycleEventAnnotations={tabData.thighCoordinationData.Left.annotations}
                    />
                  </IonCol>
                  <IonCol size='6' className='ion-no-padding'>
                    <ProximodistalSequenceGraph
                      data={tabData.euclideanNormData.mean.Right.series}
                      title={'Right'}
                      graphAnnotations={tabData.proximoDistalSequenceAnnotations.Right}
                      yAxis={tabData.euclideanNormData.mean.Right.yAxis}
                      xAxisStart={tabData.annotations_Right[3]}
                      cycleEventAnnotations={tabData.thighCoordinationData.Right.annotations}
                    />
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </Card>
          </IonCol>
        </IonRow>
        <IonRow className='ion-no-padding'>
          <IonCol size='4' className=''>
            <ScissorsColumnChart
              title={'Scissors velocity'}
              data={tabData.scissorsBarGraphData}
              yAxisLabel={tabData.HamstringData.yAxisLabelVelocity}
            />
          </IonCol>
          <IonCol size='4' className=''>
            <ThighBarGraph
              data={tabData.HamstringData.thighVelocityBarData}
              title={'Hamstring propulsion - Velocity'}
              xAxisCategories={['Peak TSw', 'AV. TSw', 'Peak TSt', 'Av. Stance']}
              stdData={tabData.HamstringData.thighVelocityBarData_std}
              yAxisLabel={tabData.HamstringData.yAxisLabelVelocity}
            />
          </IonCol>
          <IonCol size='4' className=''>
            <ThighBarGraph
              data={tabData.HamstringData.thighAccelerationBarData}
              title={'Hamstring propulsion - Acceleration'}
              xAxisCategories={['TSw', 'Stance']}
              stdData={tabData.HamstringData.thighAccelerationBarData_std}
              yAxisLabel={tabData.HamstringData.yAxisLabelAcceleration}
            />
          </IonCol>
        </IonRow>

        {/* Scissors Table */}
        <IonRow className='ion-no-padding'>
          <IonCol size='12' className=''>
            <Card style={{ margin: 0 }}>
              <CardHeader title={'Scissors Velocity & Acceleration'} />
              <IonCardContent>
                <DataTable>
                  <DataTableHeader titles={['', 'Left', 'Right', 'Symmetry']} />
                  <DataTableSection title={'Scissors'}>
                    <IonRow style={{ width: '100%' }}>
                      <DataTableColumn type='key'>Average Velocity</DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.scissors.averageVelocity.left +
                          ' ' +
                          tabData.scissorsTableData.scissors.averageVelocity.unit}
                      </DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.scissors.averageVelocity.right +
                          ' ' +
                          tabData.scissorsTableData.scissors.averageVelocity.unit}
                      </DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.scissors.averageVelocity.symmetry + ' %'}
                      </DataTableColumn>
                    </IonRow>
                  </DataTableSection>

                  <DataTableSection title={'Average Velocities'}>
                    <IonRow>
                      <DataTableColumn type='key'>Terminal Swing</DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.averageVelocities.terminalSwing.left +
                          ' ' +
                          tabData.scissorsTableData.averageVelocities.terminalSwing.unit}
                      </DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.averageVelocities.terminalSwing.right +
                          ' ' +
                          tabData.scissorsTableData.averageVelocities.terminalSwing.unit}
                      </DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.averageVelocities.terminalSwing.symmetry + ' %'}
                      </DataTableColumn>
                    </IonRow>
                    <IonRow>
                      <DataTableColumn type='key'>Stance</DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.averageVelocities.stance.left +
                          ' ' +
                          tabData.scissorsTableData.averageVelocities.stance.unit}
                      </DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.averageVelocities.stance.right +
                          ' ' +
                          tabData.scissorsTableData.averageVelocities.stance.unit}
                      </DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.averageVelocities.stance.symmetry + ' %'}
                      </DataTableColumn>
                    </IonRow>
                  </DataTableSection>
                  <DataTableSection title={'Peak velocities and accelerations'}>
                    <IonRow>
                      <DataTableColumn type='key'>Velocity Terminal Swing</DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing.left +
                          ' ' +
                          tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing.unit}
                      </DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing.right +
                          ' ' +
                          tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing.unit}
                      </DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing.symmetry +
                          ' %'}
                      </DataTableColumn>
                    </IonRow>
                    <IonRow>
                      <DataTableColumn type='key'>Velocity Terminal Stance</DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.left +
                          ' ' +
                          tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.unit}
                      </DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.right +
                          ' ' +
                          tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.unit}
                      </DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.symmetry + ' %'}
                      </DataTableColumn>
                    </IonRow>
                    <IonRow>
                      <DataTableColumn type='key'>Acceleration Terminal Swing</DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing.left +
                          ' ' +
                          tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing.unit}
                      </DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing.right +
                          ' ' +
                          tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing.unit}
                      </DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing.symmetry +
                          ' %'}
                      </DataTableColumn>
                    </IonRow>
                    <IonRow>
                      <DataTableColumn type='key'>Acceleration Stance</DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.left +
                          ' ' +
                          tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.unit}
                      </DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.right +
                          ' ' +
                          tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.unit}
                      </DataTableColumn>
                      <DataTableColumn type='value'>
                        {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.symmetry + ' %'}
                      </DataTableColumn>
                    </IonRow>
                  </DataTableSection>
                </DataTable>
              </IonCardContent>
            </Card>
          </IonCol>
        </IonRow>

        <InfoToast
          isOpen={showInfoToast}
          onClose={() => setShowInfoToast(false)}
          message='All graphs can be enlarged by clicking / tapping on the card.'
        />
      </>
    );
  }
};

export function firebaseErrorToUserError(error: string): string {
  switch (error) {
    case 'auth/expired-action-code':
    case 'auth/invalid-action-code':
      return 'The reset password link has expired. Please request a new link to reset your password.';
    case 'auth/user-disabled':
      return 'This account has been disabled. Please contact support for assistance.';
    case 'auth/user-not-found':
      return 'No account exists with this information. Please sign up or contact support for help.';
    case 'auth/weak-password':
      return 'The password you entered is too weak. Please ensure it meets the password requirements.';
    case 'auth/generic-change-error':
      return 'An error has occurred, please request a new link to reset your password. If issues persist contact support.';
  }

  return 'An unknown error occurred. Please contact support for assistance.';
}

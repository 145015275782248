import { IonCard, IonText } from '@ionic/react';

import styles from './index.module.css';
import { Icon, IconName } from '../Icon';
import { PropsWithChildren } from 'react';
import { IconRotate } from '../Icon/types';
import { mergeClassNames } from '../../utilities/mergeClassNames';

interface SharedQuickLinkCardProps {
  icon: IconName;
  color: 'green' | 'yellow' | 'teal';
  showOn: 'production' | 'development';
}

interface RouterLink extends SharedQuickLinkCardProps {
  routerLink: string;
  routerDirection: 'forward';
  onClick?: never;
}

interface Button extends SharedQuickLinkCardProps {
  onClick: () => void;
  routerLink?: never;
  routerDirection?: never;
}

export type QuickLinkCardProps = RouterLink | Button;

export const QuickLinkCard = ({
  icon,
  children,
  color,
  onClick,
  routerDirection,
  routerLink,
}: PropsWithChildren<QuickLinkCardProps>) => {
  return (
    <IonCard
      {...(typeof routerLink === 'string' ? { routerLink, routerDirection } : { onClick, button: true })}
      className={mergeClassNames(styles.card, 'ion-activated', onClick && styles.button)}
    >
      <div className={mergeClassNames(styles.color, styles[color])}>
        <Icon className={styles.icon} name={icon} />
      </div>

      <IonText className={styles.text}>{children}</IonText>

      <Icon name='chevron' direction={IconRotate.East} color='#829696' className={styles.icon} size={14} />
    </IonCard>
  );
};

import { IonToast } from '@ionic/react';
import React from 'react';
import { JSX as IonicJSX } from '@ionic/core';

// Components

// Styles
import styles from './Toast.module.css';

// Utils
import { mergeClassNames } from '../../utilities/mergeClassNames';

// Images
import warningSign from '../../assets/icons/warningSign.svg';

type IonicToastProps = IonicJSX.IonToast;

interface ToastProps extends IonicToastProps {
  clearError?: () => void;
  type: 'info' | 'warning';
}

/**
 * Toast component that displays a message to the user.
 *
 * @param {Object} props - The properties object.
 * @param {function} props.clearError - Function to clear the error when the toast is dismissed.
 * @param {string} props.type - The type of the toast, can be 'info' or 'warning'.
 * @param {boolean} props.isOpen - Boolean indicating whether the toast is open.
 * @param {string} [props.message] - The message to display in the toast.
 * @param {string} [props.position='bottom'] - The position of the toast on the screen.
 * @param {string} [props.header] - The header text of the toast.
 * @param {string} [props.className] - Additional class names for the toast.
 * @param {React.ReactNode} [props.icon] - Icon to display in the toast.
 *
 * @returns {JSX.Element} The rendered Toast component.
 */
export const Toast = ({ clearError, type, isOpen, duration = 5000, ...props }: ToastProps): JSX.Element => {
  return (
    <IonToast
      isOpen={isOpen}
      onDidDismiss={clearError ? () => clearError() : undefined}
      duration={duration}
      message={props.message}
      position={props.position || 'bottom'}
      header={props.header}
      className={mergeClassNames(styles.toast, type === 'info' && styles.info, type === 'warning' && styles.warning)}
      icon={type === 'warning' ? warningSign : undefined}
    />
  );
};

import { IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import IBimageVarusValgus from './assets/IBimageVarusValgus.png';

// Slides
import { SwiperSlide } from 'swiper/react';
import { VerticalSwiper } from '../../../../components/swiper';

// Styles
import styles from './ModalContent.module.css';

export const VarusValgusModalContent = () => {
  return (
    <>
      <VerticalSwiper>
        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '100%' }}>
              <IonCol size='12' size-lg='7' size-xl='7'>
                <div>
                  <IonImg src={IBimageVarusValgus} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol>
                <div>
                  Throughout loading response, alignment of the upper and lower leg should remain neutral (zero degrees)
                  to ensure a controlled increase level of pressure on the tibial plateau and menisci. The alignment is
                  visualized by the black perpendicular line.
                  <br></br>
                  <br></br>
                  When a negative angle occurs (&lt; -10°), the condition is assessed as valgus. A positive angle is
                  qualified as varus (&gt; 10°).
                  <br></br>
                  <br></br>
                  <strong>Potential causes</strong>
                  <br></br>
                  <ul>
                    <li>Poor abductor strength</li>
                    <li>Poor gluteal strength</li>
                    <li>Arthritis</li>
                    <li>Weak knee ligaments</li>
                  </ul>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </VerticalSwiper>
    </>
  );
};

import styles from './CustomLoading.module.css';
import { Icon, IconName } from '../Icon';
import { IconRotate, IconSize } from '../Icon/types';

interface CustomLoadingProps {
  size?: 'small' | 'medium' | 'large';
  message: string;
  leadingIcon?: IconName;
  leadingIconDirection?: IconRotate;
}

export const CustomLoading = ({ size = 'medium', message, leadingIcon, leadingIconDirection }: CustomLoadingProps) => {
  const iconSizesSwitch = {
    small: IconSize.extraSmall,
    medium: IconSize.medium,
    large: IconSize.large,
  };

  return (
    <div className={styles.spinnerContainer}>
      <svg xmlns='http://www.w3.org/2000/svg' width='130' height='130' viewBox='0 0 130 130' fill='none'>
        <circle
          className={styles.inner}
          cx='65'
          cy='65'
          r='32'
          strokeWidth='6'
          strokeLinecap='round'
          strokeDasharray='0.1 18.25'
        />
        <circle
          className={styles.middle}
          cx='65'
          cy='65'
          r='48'
          strokeWidth='4'
          strokeLinecap='round'
          strokeDasharray='0.1 17.75'
        />
        <circle
          className={styles.outer}
          cx='65'
          cy='65'
          r='64'
          strokeWidth='2'
          strokeLinecap='round'
          strokeDasharray='0.1 16'
        />
      </svg>
      <div className={styles.overlayButton}>
        {leadingIcon && <Icon name={leadingIcon} direction={leadingIconDirection} size={iconSizesSwitch[size]} />}
        <span className={styles.buttonText}>{message}</span>
      </div>
    </div>
  );
};

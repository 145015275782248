/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * Homepage.
 */

import { IonCol, IonContent, IonFooter, IonGrid, IonImg, IonPage, IonRow } from '@ionic/react';
import './Login.css';
import login_bg_shape from '../../assets/login/login_bg_shape.svg';
import { LoginForm } from '../../components/Forms/loginForm';

import styles from './Login.module.css';
import { Button } from '../../components/Button';
import { ResetPasswordForm } from '../../components/Forms/ResetPasswordForm';
import { IconRotate } from '../../components/Icon/types';
import { NewPasswordForm } from '../../components/Forms/newPasswordForm';

const PageLayout = ({
  showBackButton,
  formComponent,
}: {
  showBackButton?: boolean;
  formComponent: React.ReactNode;
}) => {
  return (
    <IonPage className={styles.page}>
      <IonContent fullscreen className={styles.wrapper}>
        {showBackButton && (
          <Button
            variant='tertiary'
            leadingIcon='chevron'
            leadingIconDirection={IconRotate.West}
            size='medium'
            href='/'
            className={styles.back}
          >
            Back
          </Button>
        )}

        <IonGrid class={styles.grid}>
          <IonRow class='ion-align-items-center'>
            <IonCol class='ion-justify-content-center'>{formComponent}</IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonFooter className={styles.footer}>
        <Button
          variant='tertiary'
          size='small'
          href='https://oryxmovementsolutions.com/privacy'
          className={styles.privacyPolicy}
          target='_blank'
        >
          Privacy Policy
        </Button>
      </IonFooter>
      <IonImg src={login_bg_shape} className={styles.background} />
    </IonPage>
  );
};

export const LoginPage: React.FC = () => {
  return <PageLayout formComponent={<LoginForm />} />;
};

export const ResetPasswordPage: React.FC = () => {
  return <PageLayout showBackButton formComponent={<ResetPasswordForm />} />;
};

export const NewPasswordPage: React.FC = () => {
  return <PageLayout showBackButton formComponent={<NewPasswordForm />} />;
};

/** Report detail page, holding tabs for analysis, kinematics, and overview */
import { IonContent, IonFabButton, IonGrid, IonIcon, IonPage } from '@ionic/react';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

// Styles
import styles from '../Styles/ReportDetailPage.module.css';
import { mergeClassNames } from '../../../utilities/mergeClassNames';

// Data
import { ReportInfoId } from '../../../model';
import { RunReportData } from '../../../model/reportV2.model';

// Hooks & context
import { useReportContext } from '../../../components/reportContext';
import { createRunReportData } from '../../../hooks/useReports';

// Components
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { PageTopBar } from '../../../components/PageTopBar/PageTopBar';
import { SegmentButton, SegmentedControl } from '../../../components/SegmentedControl';
import { OverviewReportTab } from '../OverviewReportTab';
import { RunAttractorsReportTab } from './RunAttractorsReportTab';
import { RunHamstringFunctionReportTab } from './RunHamstringFunctionReportTab';
import { RunKinematicsReportTab } from './RunKinematicsReportTab';
import { RunningStatusReportTab } from './RunningStatusReportTab';
import { RunScissorsReportTab } from './RunScissorsReportTab';
import { RunVariabilityReportTab } from './RunVariabilityReportTab';
import { Toast } from '../../../components/Toast/Toast';
import { FloatingButton } from '../../../components/FloatingButton/FloatingButton';

// Utils
import { useAuthContext } from '../../../components/authContext';
import { Loading } from '../../../components/Loading/Loading';
import { getTitleAndDescriptionFromReportData } from '../../../utilities/getTitleAndDescriptionFromReportData';

interface ParamTypes {
  id: ReportInfoId;
}
/** Report detail page
 * Loading the report data for the given userId and reportInfoId in the url.
 * Switch the tabs from Walk to Run depending on reportType in the loaded report data.
 * @param reportInfoId - the reportInfoId from the url
 */
export const RunReportDetailPageV2 = () => {
  const location = useLocation<{ title: string; description: string }>();
  const { id } = useParams<ParamTypes>();
  const { getReportData, dataReady, data, setDataReady, resetData, isError } = useReportContext();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('runninganalysis');
  const [runReportData, setRunReportData] = useState<RunReportData | undefined>();
  const role = useAuthContext().state.userState?.role;
  const history = useHistory();
  const [Error, setIsError] = useState(false);
  const [floatingButtons, setFloatingButtons] = useState<React.ReactNode>(null);

  const { title, description } = location.state || getTitleAndDescriptionFromReportData(runReportData);

  useEffect(() => {
    getReportData(id);
    // Cleanup the data when the component unmounts
    return () => {
      setLoading(true);
      setRunReportData(undefined);
      setDataReady(false); // Reset dataReady in the context
      resetData();
    };
  }, [id]);

  useEffect(() => {
    if (isError) {
      setIsError(true);
    }
  }, [isError]);

  useEffect(() => {
    if (data) {
      setRunReportData(createRunReportData(data));
    } else {
      setRunReportData(undefined);
    }
  }, [dataReady]);

  useEffect(() => {
    if (runReportData !== undefined && data) {
      setLoading(false);
    }
  }, [runReportData]);

  useEffect(() => {
    if (import.meta.env.MODE !== 'production') console.log('reportData', runReportData);
  }, [runReportData]);

  const isVersionAbove2111 = (currentVersion: string | undefined, targetVersion: [number, number, number]) => {
    if (!currentVersion || typeof currentVersion !== 'string' || targetVersion.length !== 3) return false;

    const versionParts = currentVersion.split('.').map(Number);

    if (versionParts.length !== 3) return false;

    for (let i = 0; i < 3; i++) {
      if (versionParts[i] > targetVersion[i]) return true;
      if (versionParts[i] < targetVersion[i]) return false;
    }

    return true;
  };

  const handleOnSegmentClick = (target: string) => {
    setActiveTab(target);
    history.push(`/reportsV2/Run/${id}/${target}`);
  };

  const shouldShowHamstringSegment = useMemo(
    () => isVersionAbove2111(runReportData?.version, [2, 1, 11]) && role === 'ORYX_Admin',
    [runReportData],
  );

  const segments: SegmentButton[] = [
    { title: 'Analysis', value: 'runninganalysis', onClick: () => handleOnSegmentClick('runninganalysis') },
    { title: 'Running status', value: 'runningStatus', onClick: () => handleOnSegmentClick('runningStatus') },
    { title: 'Scissors', value: 'scissors', onClick: () => handleOnSegmentClick('scissors') },
    { title: 'Kinematics', value: 'kinematics', onClick: () => handleOnSegmentClick('kinematics') },
    { title: 'Variability', value: 'variability', onClick: () => handleOnSegmentClick('variability') },
    // only show the hamstring function segment if the user is an admin and the report is above 2.1.11
    ...(shouldShowHamstringSegment
      ? [{ title: 'Hamstring function', value: 'hamstring', onClick: () => handleOnSegmentClick('hamstring') }]
      : []),
    { title: 'Overview', value: 'overview', onClick: () => handleOnSegmentClick('overview') },
  ];

  return (
    <>
      <IonPage>
        <PageHeader
          isLoading={loading}
          topSlot={<PageTopBar backTarget='/reports' title={title} description={description} variant='simple' />}
        >
          <SegmentedControl variant='default' value={activeTab} segments={segments} />
        </PageHeader>
        <div className={styles.background}>
          <IonContent className={mergeClassNames('ion-padding', styles.content)} id='main' color='light'>
            <IonGrid fixed style={{ height: '100%' }}>
              {loading && <Loading />}
              {runReportData && (
                <>
                  <Suspense fallback={<Loading />}>
                    {activeTab === 'runninganalysis' && (
                      <RunAttractorsReportTab tabData={runReportData.runAttractorData} />
                    )}
                    {activeTab === 'runningStatus' && (
                      <RunningStatusReportTab tabData={runReportData.runningStatusData} />
                    )}
                    {activeTab === 'scissors' && <RunScissorsReportTab tabData={runReportData.runScissorsData} />}
                    {activeTab === 'kinematics' && (
                      <RunKinematicsReportTab
                        tabData={runReportData.runKinematicData}
                        setFloatingButtons={setFloatingButtons}
                      />
                    )}
                    {activeTab === 'variability' && (
                      <RunVariabilityReportTab
                        tabData={runReportData.runVariabilityData}
                        setFloatingButtons={setFloatingButtons}
                      />
                    )}
                    {activeTab === 'hamstring' && (
                      <RunHamstringFunctionReportTab
                        tabData={runReportData.runHamstringFunctionData!}
                        setFloatingButtons={setFloatingButtons}
                      />
                    )}
                    {activeTab === 'overview' && (
                      <OverviewReportTab
                        id={id}
                        fileInfo={runReportData.fileInfo}
                        reportType={runReportData.reportType}
                      />
                    )}
                  </Suspense>
                </>
              )}
            </IonGrid>
          </IonContent>
          {floatingButtons && (
            <FloatingButton slot='fixed' horizontal='end' vertical='bottom' side='top'>
              {floatingButtons}
            </FloatingButton>
          )}
        </div>
      </IonPage>
      <Toast type={'warning'} isOpen={Error} onDidDismiss={() => setIsError(false)} />
    </>
  );
};

// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

if (import.meta.env.NODE_ENV !== 'production') {
  console.log('Initializing Firebase', firebaseConfig);
  console.log('NODE_ENV', import.meta.env.MODE);
}

// Ensure that you do not initialize twice.
export const myFirebase = firebase.apps.length === 0 ? firebase.initializeApp(firebaseConfig) : firebase.app();

// Initialize Analytics and get a reference to the service
export const analytics = myFirebase.analytics();
export const database = myFirebase.firestore();

const checkEmulator = async () => {
  try {
    const response = await fetch('http://127.0.0.1:8080');
    return response.ok; // If we get a valid response, the emulator is running
  } catch (error) {
    return false; // If there's an error (e.g., no response), emulator is not running
  }
};
// Connect to emulators if running locally
checkEmulator().then((isEmulatorRunning) => {
  if (isEmulatorRunning || import.meta.env.VITE_USE_EMULATORS === 'true') {
    console.log('Using Firebase Emulators');
    database.useEmulator('127.0.0.1', 8080);
    const auth = firebase.auth();
    auth.useEmulator('http://127.0.0.1:9099');
  } else {
    console.log('Using Firebase Production Services');
  }
});

import { IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import IBimagesSwinglegrecovery1 from './assets/IBimagesSwinglegrecovery1.png';
import IBimagesSwinglegrecovery2 from './assets/IBimagesSwinglegrecovery2.png';

// Slides
import { SwiperSlide } from 'swiper/react';
import { VerticalSwiper } from '../../../../components/swiper';

// Styles
import styles from './ModalContent.module.css';

export const SwingLegRecoveryModalContent = () => {
  return (
    <>
      <VerticalSwiper>
        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimagesSwinglegrecovery1} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  After toe off, the leg should be brought in front of the body as fast as possible to ensure sufficient
                  time for it to prepare for landing.
                  <br></br>
                  <br></br>A linear deployment of the rear pendulum by simultaneous hip and knee flexion is an important
                  pre-condition for fast leg recovery
                  <br></br>
                  <br></br>
                  If executed this way, the knee will already be traveling forward with increasing velocity (&#120011;
                  <sub>𝑥</sub>) while the foot’s altitude increases.
                  <br></br>
                  <br></br>
                  This action is closely connected with the leg in front. Together they form a scissors movement. The
                  quality of scissors and swing-leg retraction primarily depend on how the recovery of the rear leg is
                  performed.
                  <br></br>
                  <br></br>
                  In fact this factor is dominant; the leg in front ‘listens’ to the rear leg and responds to it. A
                  rapid swing-leg recovery produces a rapid swing-leg retraction.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow class='ion-justify-content-center ion-align-items-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimagesSwinglegrecovery2} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  If deployment of the recovery is too ‘round’ (also see: knee extension at pre-swing), the knee will
                  already be bent quite far before hip flexion starts.
                  <br></br>
                  <br></br>
                  This will result in a poor knee velocity, delaying the recovery. As a result the leg in front will
                  have to ‘wait’ on the rear leg to pull up and will be forced to land passively and softly. A lot of
                  energy which normally would be released will be lost and there will be no leg stiffness, compromising
                  foot plant and projection.
                  <br></br>
                  <br></br>
                  The ankle remaining in a plantar flexion for a long period of time after toe off, can typically be
                  observed when the recovery is executed roundly. A fast return to neutral position seems to have a good
                  synergy with a linear execution of swing leg recovery.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </VerticalSwiper>
    </>
  );
};

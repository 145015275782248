import { IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import IBimageKneeanglemidswing1 from './assets/IBimageKneeanglemidswing1.png';
import IBimageKneeanglemidswing2 from './assets/IBimageKneeanglemidswing2.png';
// Slides
import { SwiperSlide } from 'swiper/react';
import { VerticalSwiper } from '../../../../components/swiper';

// Styles
import styles from './ModalContent.module.css';

export const KneeFlexionMidSwingRunModalContent = () => {
  return (
    <>
      <VerticalSwiper>
        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimageKneeanglemidswing1} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  Assessment of the exact knee angle in mid-swing is not necessarily relevant; in contrast to walking,
                  the knee angle shows a lot of variation because it highly depends on the speed.
                  <br></br>
                  <br></br>A proper running technique can be executed with a lot of knee flexion but also at just 90
                  degrees.
                  <br></br>
                  <br></br>
                  However, when the knee angle is smaller than 90 degrees, the leg’s mass will slow down the pace of the
                  pendulum.
                  <br></br>
                  <br></br>
                  In addition an obtuse knee angle will hamper a proper crossed extensor reflex in the following
                  propulsion phase.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow class='ion-justify-content-center ion-align-items-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimageKneeanglemidswing2} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  The knee angle at midswing is an important parameter when assessing people recovering from knee
                  injuries or surgeries like ACL-reconstructions.
                  <br></br>
                  <br></br>
                  Typically, massive differences in symmetry can reveal the existence of problems.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </VerticalSwiper>
    </>
  );
};

import { IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react';

// Styles
import styles from './PageTopBar.module.css';

// Types
import type { ComponentProps, PropsWithChildren } from 'react';
import { useHistory } from 'react-router';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { IconRotate } from '../Icon/types';

interface PageTopBarProps extends ComponentProps<typeof IonHeader> {
  backTarget?: string;
  variant?: 'simple' | 'detailed';
  title: string;
  description?: string;
}

export const PageTopBar = ({
  children,
  title,
  description,
  backTarget,
  ...props
}: PropsWithChildren<PageTopBarProps>) => {
  const history = useHistory();

  const goBack = (backTarget: string) => {
    history.push(backTarget);
  };

  return (
    <IonHeader className='ion-no-padding' {...props}>
      <IonToolbar className={styles.toolbar}>
        {backTarget && (
          <IonButtons slot='start'>
            <Button variant='tertiary' onClick={() => goBack(backTarget)} className={styles.backButton}>
              <Icon name='chevron' size={13} direction={IconRotate.West} />
              Back
            </Button>
          </IonButtons>
        )}
        <IonTitle className={styles.titleContainer}>
          <span className={styles.title} style={{ marginRight: '4px' }}>
            {title}
          </span>

          {description && (
            <>
              <span className={styles.seperator}> | </span>
              <span className={styles.description} style={{ marginLeft: '4px' }}>
                {description}
              </span>
            </>
          )}
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

import { IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import IBimagefootplantfromabove1 from './assets/IBimagefootplantfromabove1.png';
import IBimagefootplantfromabove2 from './assets/IBimagefootplantfromabove2.png';

// Slides
import { SwiperSlide } from 'swiper/react';
import { VerticalSwiper } from '../../../../components/swiper';

// Styles
import styles from './ModalContent.module.css';

export const FootplantFromAboveModalContent = () => {
  return (
    <>
      <VerticalSwiper>
        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimagefootplantfromabove1} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  At landing, large ground reaction forces (GRF) must be absorbed. Because the knee and hip are in
                  almost full extension at initial contact (see: swing-leg retraction), the leg should preferably be
                  brought to the ground in the same direction as the GRF when planted. This minimizes the resistance arm
                  and hence the impinging torque round the knee and hip.
                  <br></br>
                  <br></br>
                  The best approach for landing is to bring the foot to the ground in the same direction as the vector
                  of the GRF will have on landing. This way the vector of the GRF and leg position will coincide as much
                  as possible.
                  <br></br>
                  <br></br>
                  Good movement, characterized by this coincidence of the trajectory of the foot with the ground and the
                  direction of the force vector, can be summed up in the description ‘foot plant from above’.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>

        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow class='ion-justify-content-center ion-align-items-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='6' size-xl='6' className='ion-no-padding'>
                <div>
                  <IonImg src={IBimagefootplantfromabove2} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='5' size-xl='5'>
                <div>
                  If the foot is slipped in, there will be an angle between the direction of the vector and the leg
                  position. The moment at which increasing friction fixes the foot on the ground, will also become
                  somewhat unpredictable. The angle and the variable foot plant make it difficult to control the
                  position properly.
                  <br></br>
                  <br></br>
                  Foot plant from above is closely related to the hip lock and swing-leg retraction and hence is a
                  manifestation of self-organization in a crossed extensor reflex. At high speed, the GRF will mainly be
                  vertical. However, what determines the maximum running speed is the size of the (relatively small)
                  rearward horizontal component of the force. This means that the foot will have to be pulled backwards
                  in relation to the body before landing. Swing-leg retraction ensures this. In other words, foot plant
                  from above is a function of swing leg retraction.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </VerticalSwiper>
    </>
  );
};

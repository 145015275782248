import { IonButtons, IonHeader, IonImg, IonProgressBar, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory } from 'react-router';

// Styles
import styles from './PageHeader.module.css';

// Types
import type { ComponentProps, PropsWithChildren, ReactNode } from 'react';

// Components
import { Button } from '../Button';
import { Icon } from '../Icon';
import { IconRotate } from '../Icon/types';

// Utils
import { mergeClassNames } from '../../utilities/mergeClassNames';

// Assets
import backgroundPattern from '../../assets/bg-pattern-pageheader.png';

interface PageHeaderProps extends ComponentProps<typeof IonHeader> {
  backTarget?: string;
  isLoading?: boolean;
  progress?: number;
  title?: string;
  topSlot?: ReactNode;
}

export const PageHeader = ({
  children,
  title,
  backTarget,
  isLoading,
  progress,
  topSlot,
  ...props
}: PropsWithChildren<PageHeaderProps>) => {
  const history = useHistory();

  const goBack = (backTarget: string) => {
    history.push(backTarget);
  };

  return (
    <IonHeader className={mergeClassNames('ion-no-padding', styles.header)} {...props}>
      {topSlot}

      <IonToolbar className={mergeClassNames(styles.toolbar, topSlot && styles.withTopSlot)}>
        {backTarget && !isLoading && (
          <IonButtons slot='start'>
            <Button variant='tertiary' onClick={() => goBack(backTarget)} className={styles.backButton}>
              <Icon name='chevron' size={13} direction={IconRotate.West} />
              Back
            </Button>
          </IonButtons>
        )}

        {children ?? <IonTitle>{title}</IonTitle>}

        {isLoading && (
          <IonProgressBar
            value={progress}
            className={styles.progress}
            type={progress === undefined ? 'indeterminate' : 'determinate'}
          />
        )}
      </IonToolbar>

      <IonImg src={backgroundPattern} className={styles.background} />
    </IonHeader>
  );
};

/** Kinematic data upload page */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRadioGroup,
  IonRow,
  IonText,
  IonToast,
} from '@ionic/react';
import { useEffect, useState } from 'react';

// Styles
import styles from './Upload.module.css';

// Components
import { useAuthContext } from '../../components/authContext';
import { ErrorToast } from '../../components/ErrorToast';
import { ORYXHeader } from '../../components/menuItems/Header';
import { Radio } from '../../components/Radio';
import { UploadArea } from '../../components/FileInput/uploadArea';

// Hooks
import { FileData, reportOption, useFirebaseUpload } from '../../hooks/useFirebaseUpload';
import { Button } from '../../components/Button';
import { Toast } from '../../components/Toast/Toast';
import { CustomLoading } from '../../components/Loading/CustomLoading';
import { Spinner } from '../../components/Spinner/Spinner';
import { IconRotate } from '../../components/Icon/types';
import { Input } from '../../components/Input';
import { IonInputCustomEvent } from '@ionic/core';
import { mergeClassNames } from '../../utilities/mergeClassNames';
import { Tag } from '../../components/Tag/Tag';

/** Kinematic data upload page
 * Using the useFirebaseUpload hook to handle the actual upload to cloud storage
 */
export const UploadPage = () => {
  // setting up the hook to upload file and track its progress
  const { dataResponse, isLoading, isError, errorMessage, progress, setUploadData, clearError } = useFirebaseUpload();
  const user = useAuthContext().state.userState?.user;
  const [userConfirmedReportType, setUserConfirmedReportType] = useState<reportOption>('undefined');
  const [isTouched, setIsTouched] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [detectedOption, setDetectedOption] = useState('');
  const [showToast, setShowToast] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [option, setOption] = useState<reportOption>('undefined');

  const [tags, setTags] = useState('');

  const [file, setFile] = useState<FileData>();
  const markTouched = () => {
    setIsTouched(true);
  };

  function handleFocusEvent(ev: IonInputCustomEvent<FocusEvent>) {
    ev.target.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  function handleReportType(reportType: reportOption, file: any) {
    setOption(reportType);
    setFile(file);
    setDetectedOption(reportType);
    setDisabled(false);
  }

  function handleUndefined(reportType: reportOption, file: any) {
    setOption(reportType);
    setFile(file);
  }

  const handleFileSelect = (files: FileList) => {
    const startIndex = files[0].name.indexOf('- ') + 2;
    const endIndex = files[0].name.indexOf('.csv');
    const matchArray = files[0].name.match(/\(+\d+\)+/g);
    const index = matchArray ? files[0].name.indexOf(matchArray[0]) : -1;

    if (index < 0) {
      const reportType = files[0].name.substring(startIndex, endIndex);

      if (reportType === 'Walk') handleReportType(reportType, files[0]);
      else if (reportType === 'Run') handleReportType(reportType, files[0]);
      else if (reportType === 'Squat') handleReportType(reportType, files[0]);
      else if (reportType === 'Single Leg Squat') handleReportType('SLSquat', files[0]);
      else handleUndefined('undefined', files[0]);

      // setOption(e.target.files[0].name.substring(startIndex, endIndex));
    } else {
      const reportType = files[0].name.substring(startIndex, index);

      if (reportType === 'Walk') handleReportType(reportType, files[0]);
      else if (reportType === 'Run') handleReportType(reportType, files[0]);
      else if (reportType === 'Squat') handleReportType(reportType, files[0]);
      else if (reportType === 'Single Leg Squat') handleReportType('SLSquat', files[0]);
      else handleUndefined('undefined', files[0]);
      // setOption(e.target.files[0].name.substring(startIndex, indexIndex));
    }
    // e.target.files.length > 0 && setFile(e.target.files[0]);
  };

  const handleTagsSelect = (e: any) => {
    setTags(e.detail.value);
  };

  const handleOptionChange = (ev: Event) => {
    setUserConfirmedReportType((ev.target as HTMLInputElement).value as reportOption);
    setOption((ev.target as HTMLInputElement).value as reportOption);
    setDisabled(false);
  };

  const handleFileRemove = () => {
    setFile(undefined);
    setOption('undefined');
    setDisabled(true);
  };

  // The actual file upload handler once you click Submit in the form
  const upload = async (e: React.FormEvent) => {
    e.preventDefault();

    setFormSubmitted(true);

    if (!!user && !!file) {
      const user_id = user.uid;

      // Perform the actual file upload using the hook
      await setUploadData({ user_id, tags, option, file });
      setFile(undefined);
      setOption('undefined');
      setUserConfirmedReportType('undefined');
      setDisabled(true);
      handleFileRemove();
    }
  };

  useEffect(() => {
    if (dataResponse || progress?.value === 100) {
      setShowToast(true);
    }
  }, [dataResponse, progress]);

  return (
    <>
      <IonPage>
        {/* <ORYXHeader backTarget='/reports' loading={isLoading && !!progress} progress={progress?.value} /> */}

        <IonContent className='ion-padding' id='main' color='light'>
          <IonGrid fixed className='' style={{ height: '100%', alignContent: 'space-evenly' }}>
            {isLoading && progress && (
              <IonRow className='ion-padding-top ion-margin-top ion-align-items-center ion-justify-content-center'>
                <CustomLoading
                  message={'Uploading file...'}
                  leadingIcon='document'
                  leadingIconDirection={IconRotate.North}
                />
              </IonRow>
            )}

            {!isLoading && !progress && (
              <form noValidate onSubmit={upload} style={{ height: '100%' }}>
                <IonGrid fixed className='' style={{ height: '100%', alignContent: 'space-evenly' }}>
                  <IonRow className='ion-padding-top ion-margin-top ion-align-items-center'>
                    <IonCol size='6'>
                      <IonRow>
                        <IonCol size='12'>
                          <UploadArea onChange={handleFileSelect} onFileRemove={handleFileRemove} />
                          {!formSubmitted && file === undefined && !disabled && (
                            <IonText>
                              <p className='ion-padding-start errorText ion-text-center'>Data file is required</p>
                            </IonText>
                          )}
                        </IonCol>
                      </IonRow>
                      <IonRow className=''>
                        <IonList
                          mode='md'
                          class={mergeClassNames(styles['no-padding'], styles.list)}
                          style={{ width: '100%' }}
                        >
                          <Input
                            name='tags'
                            type='text'
                            label='Tags'
                            label-placement='stacked'
                            onIonChange={handleTagsSelect}
                            onIonFocus={handleFocusEvent}
                            value={tags}
                            variant='light'
                          />
                        </IonList>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <Tag size='sm'>Seperate multiple tags by a comma</Tag>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    {file && file !== undefined && (
                      <IonCol size='6'>
                        {option === 'undefined' && (
                          <IonRow className='ion-align-items-center ion-justify-content-center ion-margin-vertical'>
                            <IonText className='ion-text-center'>
                              <p>
                                We could not detect your reporttype. Please select the correct report type from the list
                                below.
                              </p>
                            </IonText>
                          </IonRow>
                        )}
                        {option !== 'undefined' && (
                          <>
                            <IonRow className='ion-align-items-center ion-justify-content-center ion-margin-vertical'>
                              <IonText className='ion-text-center'>
                                <p>
                                  We have detected that you are uploading a{' '}
                                  <strong> {detectedOption ? detectedOption : option} </strong> report. Is this correct?
                                </p>
                              </IonText>
                            </IonRow>
                            <IonRow className='ion-align-items-center ion-justify-content-center ion-margin-vertical'>
                              <IonText className='ion-text-center'>
                                <p>If not please select the correct report type from the list below.</p>
                              </IonText>
                            </IonRow>
                          </>
                        )}
                        <IonRow className='ion-justify-content-center ion-margin-vertical'>
                          <IonList lines='none' className='' style={{ width: '75%', borderRadius: '12px' }}>
                            <IonRadioGroup
                              value={userConfirmedReportType != 'undefined' ? userConfirmedReportType : option}
                              onIonChange={handleOptionChange}
                            >
                              <IonItem>
                                <Radio value='Walk'>Walk</Radio>
                              </IonItem>
                              <IonItem>
                                <Radio value='Run'>Run</Radio>
                              </IonItem>
                              <IonItem>
                                <Radio value='Squat'>Double Leg Squat</Radio>
                              </IonItem>
                              <IonItem>
                                <Radio value='SLSquat'>Single Leg Squat</Radio>
                              </IonItem>
                            </IonRadioGroup>
                          </IonList>
                        </IonRow>
                      </IonCol>
                    )}
                  </IonRow>

                  <IonRow>
                    <IonCol size='6'>
                      <Button type='submit' disabled={disabled}>
                        Submit
                      </Button>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </form>
            )}
          </IonGrid>
        </IonContent>
        <Toast
          type='info'
          isOpen={showToast}
          message={'Datafile uploaded successfully'}
          position='bottom'
          color='success'
          duration={2500}
          onDidDismiss={() => setShowToast(false)}
        />

        <Toast
          type='warning'
          isOpen={isError}
          message={errorMessage}
          position='bottom'
          duration={2500}
          onDidDismiss={() => clearError()}
        />
      </IonPage>
    </>
  );
};

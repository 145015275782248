import { ReportInfo } from '../model';
import { RunReportData, SingleLegSquatReportData, SquatReportData, WalkReportData } from '../model/reportV2.model';

type ReportData = RunReportData | WalkReportData | SquatReportData | SingleLegSquatReportData;

export const getTitleAndDescriptionFromReportData = (reportData: ReportData | undefined) => {
  if (!reportData) return { title: '', description: '' };

  const title = `${reportData.fileInfo.reportName}`;

  if (!reportData.fileInfo.uploadTimestamp) return { title, description: 'Uploaded at unknown time' };

  // convert unix timestamp to format 11 Dec 2024 - 01:08 PM
  const date = new Date(reportData.fileInfo.uploadTimestamp * 1000);
  const description = `${date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })} - ${date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}`;

  return { title, description };
};

export const getTitleAndDescriptionFromReportInfo = (reportData: ReportInfo | undefined) => {
  if (!reportData) return { title: '', description: '' };

  const title = `${reportData.reportName}`;

  if (!reportData.uploadTimestamp) return { title, description: 'Uploaded at unknown time' };

  // convert unix timestamp to format 11 Dec 2024 - 01:08 PM
  const date = reportData.uploadTimestamp;
  const description = `${date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })} - ${date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}`;

  return { title, description };
};

export const getTitleAndDescriptionFromInfo = (name: string, date: Date) => {
  if (!name && !date) return { title: '', description: '' };

  const title = `${name}`;

  if (!date) return { title, description: 'Uploaded at unknown time' };

  const description = `${date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })} - ${date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}`;

  return { title, description };
};

import React from 'react';
import { IonGrid, IonRow, IonCol, IonImg } from '@ionic/react';
import IBimageMobilityHip from './assets/IBimageMobilityHip.png';

// Slides
import { SwiperSlide } from 'swiper/react';
import { VerticalSwiper } from '../../../../components/swiper';

// Styles
import styles from './ModalContent.module.css';

export const HipMobilityStatusModalContent = () => {
  return (
    <>
      <VerticalSwiper>
        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow className='ion-align-items-center ion-justify-content-center' style={{ height: '100%' }}>
              <IonCol size='11' size-lg='3' size-xl='3' className=''>
                <div>
                  <IonImg src={IBimageMobilityHip} class={styles.avatar} />
                </div>
              </IonCol>
              <IonCol size='11' size-lg='8' size-xl='8' className=''>
                <div>
                  The hip is characterized by its many degrees of freedom. Its mobility is enormous (120&deg; of
                  flexion, 13-15&deg; extension, 20&deg; adduction, 40-60&deg; abduction, 13-40&deg; internal rotation
                  and 36-60&deg; external rotation). Mobility in gait however is restricted.
                  <br></br>
                  <br></br>
                  Maximum hip extension in the sagittal plane is 13-15&deg;. Maximum hip flexion prior to Initial
                  Contact is 25-30&deg;, totaling hip ROM to approximately 40&deg;.
                  <br></br>
                  <br></br>
                  In the frontal plane, abduction should be limited to 15&deg;. Maximum abduction is reached at
                  midstance. The hip should be in approximately 10&deg; adduction at initial contact and 5&deg; at
                  initial swing.
                  <br></br>
                  <br></br>
                  The sum of internal and external rotation largely depends on pelvis rotation but is typically does not
                  exceed 10-15&deg;, with its peak at the end of pre-swing.
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </VerticalSwiper>
    </>
  );
};

import { IonCard, IonCardContent, IonIcon, IonImg, IonSpinner, IonText } from '@ionic/react';
import { useHistory } from 'react-router';
import { useReportContext } from '../reportContext';
import { useState } from 'react';

// Components
import { Icon } from '../Icon';
import { ActionSheet } from './ActionSheet';
import { Tag } from '../Tag/Tag';
import { Toast } from '../Toast/Toast';

// Icons
import Walking from '../../assets/icons/Walking.png';
import Running from '../../assets/icons/Running.png';
import squatter from '../../assets/icons/Squat.png';
import slsquatter from '../../assets/icons/SingleLegSquat.png';
import { ellipsisVertical } from 'ionicons/icons';

// Images
import ReportCardPattern from '../../assets/report-card-pattern.svg';

// Types
import { IconRotate } from '../Icon/types';
import { ReportInfo, ReportStatus, ReportType } from '../../model';

// Styles
import styles from './ReportListCard.module.css';
import { mergeClassNames } from '../../utilities/mergeClassNames';
import {
  getTitleAndDescriptionFromReportData,
  getTitleAndDescriptionFromReportInfo,
} from '../../utilities/getTitleAndDescriptionFromReportData';

interface ReportListCardProps {
  id: string;
  name: string;
  date: Date;
  reportType: ReportType;
  majorVersion: number;
  tags: string[];
  reportInfo: ReportInfo;
}

export const ReportListCard = ({ id, name, date, reportType, majorVersion, tags, reportInfo }: ReportListCardProps) => {
  const { title, description } = getTitleAndDescriptionFromReportInfo(reportInfo);
  const history = useHistory();
  const pending = reportInfo.status === ReportStatus.PENDING;
  const error =
    reportInfo.status === ReportStatus.ERROR ||
    reportInfo.reportType === ReportType.ERROR ||
    reportInfo.reportType === ReportType.KNEEL ||
    reportInfo.reportType === ReportType.KNEER;

  const { doDelete } = useReportContext();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [showErrorDoDelete, setShowErrorDoDelete] = useState(false);
  const [PDFError, setPDFError] = useState(false);

  const imageMap = new Map([
    [ReportType.WALK, Walking],
    [ReportType.RUN, Running],
    [ReportType.SQUAT, squatter],
    [ReportType.SLSQUAT, slsquatter],
  ]);

  const activityNamesMap = new Map([
    [ReportType.WALK, 'Walk'],
    [ReportType.RUN, 'Run'],
    [ReportType.SQUAT, 'Squat'],
    [ReportType.SLSQUAT, 'Single Leg Squat'],
  ]);

  const routeSegmentMap = new Map([
    [ReportType.WALK, 'Walk'],
    [ReportType.RUN, 'Run'],
    [ReportType.SQUAT, 'Squat'],
    [ReportType.SLSQUAT, 'SingleLegSquat'],
  ]);

  const handleClick = () => {
    if (routeSegmentMap.has(reportType)) {
      history.push(`/${majorVersion === 1 ? 'reportsV1' : 'reportsV2'}/${routeSegmentMap.get(reportType)}/${id}`, {
        title,
        description,
      });
    }
  };

  return (
    <>
      <ActionSheet
        isOpen={showActionSheet}
        onClose={() => setShowActionSheet(false)}
        reportInfo={reportInfo}
        doDelete={doDelete}
        // onGeneratePDF={handleGeneratePDF}
        showDeleteError={setShowErrorDoDelete}
        showPDFError={setShowErrorDoDelete}
      />

      <IonCard className={mergeClassNames(styles.card, error && styles.error)} onClick={handleClick}>
        <div className={styles.color}>
          <IonImg src={imageMap.get(reportType) ?? Walking} className={styles.icon} />

          <IonImg src={ReportCardPattern} className={styles.pattern} />
        </div>

        <IonCardContent className={styles.content}>
          <div className={pending ? styles.header_pending : styles.header}>
            {pending ? (
              <div className=''>
                <IonSpinner color='tertiary' name='bubbles' />
              </div>
            ) : (
              <>
                <IonText className={styles.name}>{activityNamesMap.get(reportType)}</IonText>
                <IonText class={styles.date}>
                  <span className={styles.dateString}>
                    {date.toLocaleDateString(undefined, {
                      month: 'short',
                      year: 'numeric',
                      day: 'numeric',
                    })}
                  </span>
                  {date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}
                </IonText>
                <IonIcon
                  icon={ellipsisVertical}
                  onClick={(event) => {
                    event.stopPropagation();
                    setShowActionSheet(true);
                  }}
                  className={styles.chevron}
                />
              </>
            )}
          </div>

          <div className={styles.bottom}>
            <IonText className={styles.activity}>{name}</IonText>
            <Icon name='chevron' direction={IconRotate.East} className={styles.chevron} color='#829696' />
          </div>

          <div className={styles.tags}>
            {tags[0] != '' && (
              <>
                {tags.map((tag) => (
                  <Tag variant='blue' size='sm' key={tag}>
                    {' '}
                    {tag}{' '}
                  </Tag>
                ))}
              </>
            )}
          </div>

          {error && (
            <div className={styles.errorMessage}>
              <IonText className={styles.errorText}>
                {reportInfo.status === ReportStatus.ERROR &&
                  `Sorry, we couldn't process your data. Please contact support.`}
                {reportInfo.reportType === ReportType.ERROR &&
                  `Sorry, we couldn't process your data. Please contact support.`}
                {reportInfo.reportType === ReportType.KNEEL && `Left Thigh and Calf Sensors seem to be swapped.`}
                {reportInfo.reportType === ReportType.KNEER && `Right Thigh and Calf Sensors seem to be swapped.`}
              </IonText>
            </div>
          )}
        </IonCardContent>
      </IonCard>

      <Toast
        isOpen={showErrorDoDelete}
        onDidDismiss={() => setShowErrorDoDelete(false)}
        type='warning'
        message={`No report ID for the given report ${reportInfo.filename}.`}
        header='Error deleting file'
      />
      <Toast
        isOpen={PDFError}
        onDidDismiss={() => setPDFError(false)}
        type='warning'
        message={`Can't download PDF for ${reportInfo.filename}, please contact support.`}
        header='Error downloading file'
      />
    </>
  );
};

/** Reports page, showing metadata for available reports for the current user */
import { IonCol, IonContent, IonGrid, IonHeader, IonPage, IonRow, IonSearchbar, IonToolbar } from '@ionic/react';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useReportContext } from '../../components/reportContext';
import { useSearch } from '../../hooks/useSearch';
import { ReportInfo, ReportInfoDict } from '../../model';
import { analytics } from '../../config/firebaseConfig';
import { Button } from '../../components/Button';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { Toast } from '../../components/Toast/Toast';
import styles from './reportList.module.css';
import { ReportCardList } from '../../components/ReportListCard';
import { Loading } from '../../components/Loading/Loading';
import { Input } from '../../components/Input';
import { SectionHeader } from '../../components/SectionHeader';
import { mergeClassNames } from '../../utilities/mergeClassNames';

/** Map over reports sorted by uploadTimestamp */
const sortReportInfos = (reportInfoDict: ReportInfoDict): ReportInfo[] => {
  // Create array of tuples (id, reportInfo)
  const items: [string, ReportInfo][] = Object.keys(reportInfoDict).map((id: string) => {
    return [id, reportInfoDict[id]];
  });

  // Sort the array based on the second element (reportInfo.uploadTimestamp)
  const reportsSorted = items.sort((first, second) => {
    const a = second[1].uploadTimestamp.getTime();
    const b = first[1].uploadTimestamp.getTime();
    return a - b;
  });

  return reportsSorted.map(([_id, reportInfo]) => reportInfo);
};

type ReportsPageProps = {
  filteroption?: string;
};
/** Reports page, showing all reports (metadata) available for the current user
 * Loading reportInfo data from the database using the useReportInfo hook.
 */
export const ReportsPage = ({ filteroption }: ReportsPageProps) => {
  const historyHook = useHistory();
  const { reportInfoDict, isLoading, isError, clearError } = useReportContext();

  const [searchOptions, setSearchOptions] = React.useState({
    includeScore: true,
    useExtendedSearch: true,
    keys: ['filename', 'tags', 'reportType'],
  });

  const list = useMemo(() => sortReportInfos(reportInfoDict), [reportInfoDict]);
  const { onSearch, query, searchResult } = useSearch(searchOptions, list);

  const handleSearch = (e: any) => {
    localStorage.setItem('search', e.target.value);
    setSearchOptions({
      includeScore: true,
      useExtendedSearch: true,
      keys: ['filename', 'tags', 'reportType'],
    });
    onSearch(e);
  };

  useEffect(() => {
    const localStorageSearch = localStorage.getItem('search');
    if (history.state.state !== undefined) {
      analytics.logEvent('search_report');
      try {
        setSearchOptions({
          includeScore: true,
          useExtendedSearch: true,
          keys: ['reportType'],
        });
        onSearch(history.state.state['filterOption']);
        localStorage.setItem('search', history.state.state['filterOption']);
      } catch {
        if (
          localStorageSearch === '1' ||
          localStorageSearch === '2' ||
          localStorageSearch === '3' ||
          localStorageSearch === '4'
        ) {
          setSearchOptions({
            includeScore: true,
            useExtendedSearch: true,
            keys: ['reportType'],
          });
          const filterOption = Number(localStorageSearch);
          onSearch(filterOption);
        } else {
          setSearchOptions({
            includeScore: true,
            useExtendedSearch: true,
            keys: ['filename', 'tags', 'reportType'],
          });
          onSearch(localStorageSearch);
        }
      }
    }
  }, [history]);

  useEffect(() => {
    console.log(searchResult);
    console.log('reportInfoDict', reportInfoDict);
  }, [searchResult, reportInfoDict]);

  return (
    <IonPage>
      <IonHeader className={mergeClassNames(styles.header, 'ion-no-border')} color='light'>
        <IonToolbar className={styles.toolbar}>
          <IonGrid fixed className='ion-padding'>
            <IonRow className='ion-align-items-center'>
              <IonCol class='ion-no-padding ion-align-self-center'>
                <Button
                  variant='primary'
                  size='medium'
                  onClick={() => {
                    historyHook.push('/upload');
                  }}
                >
                  Upload Files
                </Button>
              </IonCol>

              <IonCol className='ion-align-self-center'>
                <Input
                  leadingIcon='search'
                  placeholder='Search'
                  value={query}
                  onIonInput={(e) => handleSearch(e)}
                  variant='secondary'
                ></Input>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonContent id='main' color='light' className={styles.content}>
        <IonGrid fixed>
          {/* <IonRow className={styles.sticky}>
            <IonCol class='ion-padding'>
              <Button
                variant='primary'
                size='medium'
                onClick={() => {
                  historyHook.push('/upload');
                }}>
                Upload Files
              </Button>
            </IonCol>

            <IonCol className='ion-align-self-center'>
              <Input
                leadingIcon='search'
                placeholder='search'
                value={query}
                onIonInput={(e) => handleSearch(e)}
                variant='primary'></Input>
            </IonCol>
          </IonRow> */}
          <IonRow>
            {isLoading ? <Loading /> : <ReportCardList data={reportInfoDict} searchResult={searchResult} />}
          </IonRow>
        </IonGrid>
      </IonContent>

      {/* Show errors, if any, as "toast" below the page */}
      <Toast
        type={'warning'}
        isOpen={isError ? true : false}
        onDidDismiss={() => clearError()}
        message={isError && isError.message}
        header={'Error fetching reports'}
      />
    </IonPage>
  );
};

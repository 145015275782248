import { IonGrid } from '@ionic/react';

// Slides
import { SwiperSlide } from 'swiper/react';
import { VerticalSwiper } from '../../../../components/swiper';

// Styles
import { RespectTheSagittalPlaneGraph } from '../Graphs';

interface ModalProps {
  data: any;
  annotations: any;
}

export const RespectTheSagittalPlaneModalContent = ({ data, annotations }: ModalProps) => {
  return (
    <>
      <VerticalSwiper>
        <SwiperSlide>
          <IonGrid className='ion-align-items-center ion-justify-content-center' style={{ height: '100%' }}>
            <div style={{ width: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
              <RespectTheSagittalPlaneGraph data={data} annotations={annotations} />
            </div>
          </IonGrid>
        </SwiperSlide>
      </VerticalSwiper>
    </>
  );
};

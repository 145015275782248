/** Component to create the Status and Symmetry fields used in reports */
import { IonCardTitle, IonCol, IonRow, IonText } from '@ionic/react';
import { colorRight, dangerColor, succesColor, warningColor } from '../../../../theme/colors';
import { mergeClassNames } from '../../../../utilities/mergeClassNames';
import styles from './Fields.module.css';

type NameFieldProps = {
  name: string;
  value: string | string[];
  status?: number;
};

/** Field component with a title parameter
 * @param name - The tile of the field
 * @param value - Field value, can be a tuple or a single field value
 */
export const NameField = ({ name, value, status }: NameFieldProps) => {
  const BorderStyle =
    status && status === 2
      ? { borderBottom: `3px solid #FFA229` } // Warning
      : status && status === 3
        ? { borderBottom: `3px solid #E7364B` } // Danger
        : { paddingBottom: '3px' }; // Dirty fix for the border
  return (
    <>
      <IonRow
        style={{ borderBottom: '1px solid #e0e0e0' }}
        className='ion-align-items-center ion-justify-content-center'
      >
        <IonCol className='ion-text-center'>
          <IonCardTitle className={styles.title}>{name}</IonCardTitle>
        </IonCol>
      </IonRow>
      <IonRow className={mergeClassNames('ion-align-items-center', 'ion-justify-content-center')} style={BorderStyle}>
        <IonText className={styles.Label}>{value}</IonText>
      </IonRow>
    </>
  );
};

type VarusValgusFieldProps = {
  name: string;
  value: string | string[];
  label: string;
  status?: number;
};

/** Field component with a title parameter
 * @param name - The tile of the field
 * @param value - Field value, can be a tuple or a single field value
 */
export const VarusValgusField = ({ name, value, label, status }: VarusValgusFieldProps) => {
  const BorderStyle =
    status && status === 2
      ? { borderBottom: `3px solid #FFA229` } // Warning
      : status && status === 3
        ? { borderBottom: `3px solid #E7364B` } // Danger
        : { paddingBottom: '3px' }; // Dirty fix for the border
  return (
    <>
      <IonRow
        style={{ borderBottom: '1px solid #e0e0e0' }}
        className='ion-align-items-center ion-justify-content-center'
      >
        <IonCol className='ion-text-center'>
          <IonCardTitle className={styles.title}>{name}</IonCardTitle>
        </IonCol>
      </IonRow>
      <IonRow className='ion-align-items-center ion-justify-content-center' style={BorderStyle}>
        <IonText className={styles.Label}>
          {value} <span className={styles.VarusValgusLabel}>({label})</span>
        </IonText>
      </IonRow>
    </>
  );
};

/** Component to draw the status dot with color set by the status field */
type StatusProps = { status: number };
export const StatusDot = ({ status }: StatusProps) => {
  const colors = [dangerColor, warningColor, succesColor];
  const BackgroundStyles = {
    backgroundColor: colors[status],
  };
  return <div className={styles.status_dot} style={BackgroundStyles} />;
};

export const StatusDot_Centered = ({ status }: StatusProps) => {
  const colors = [succesColor, warningColor, dangerColor, succesColor];
  const BackgroundStyles = {
    backgroundColor: colors[status],
  };
  return <div className={styles.status_dot_centered} style={BackgroundStyles} />;
};

type DotFieldProps = NameFieldProps & {
  status: number;
};

// /** Field component with a title, status dot, and field value */
// export const StatusDotField = ({ name, value, status }: DotFieldProps) => {
//   return (
//     <Field_v2 value={value}>
//       <IonLabel className={styles.title}>{name}</IonLabel>
//       <StatusDot status={status} />
//     </Field_v2>
//   );
// };

export const StatusDotGroup = ({ name, value, status }: DotFieldProps) => {
  return (
    <>
      <IonRow
        style={{ borderBottom: '1px solid #e0e0e0' }}
        className='ion-align-items-center ion-justify-content-center'
      >
        <IonCol className='ion-text-center'>
          <IonCardTitle className={styles.title}>{name}</IonCardTitle>
        </IonCol>
        <IonCol className='ion-text-center'>
          <StatusDot status={status} />
        </IonCol>
      </IonRow>
      <IonRow className='ion-align-items-center ion-justify-content-center'>
        <IonText className={styles.Label}>{value}</IonText>
      </IonRow>
    </>
  );
};

import { useEffect, useState } from 'react';
import { succesColor } from '../../theme/colors';

import styles from './InfoToast.module.css';
import { mergeClassNames } from '../../utilities/mergeClassNames';
import { IonButton, IonCol, IonIcon, IonRow, IonText } from '@ionic/react';
import { Icon } from '../Icon';
import { closeOutline } from 'ionicons/icons';

type legendToastProps = {
  isOpen: boolean;
  onClose: () => void;
  duration?: number;
  message: string;
};

/**
 * LegendToast component displays a toast notification with legend entries.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.isOpen - Determines if the toast is open.
 * @param {function} props.onClose - Function to call when the toast closes.
 * @param {number} [props.duration=5000] - Duration in milliseconds before the toast automatically closes.
 *
 * @returns {JSX.Element | null} The rendered LegendToast component or null if not open.
 */
export const InfoToast = ({ isOpen, onClose, duration = 3000, message }: legendToastProps) => {
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsClosing(false);
      const timer = setTimeout(() => {
        setIsClosing(true);
        setTimeout(onClose, 500);
      }, duration);

      // Clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose, duration]);

  if (!isOpen && !isClosing) return null;

  return (
    isOpen && (
      <div className={mergeClassNames(styles.infoToast, isClosing ? styles['slide-out'] : undefined)}>
        <div className={styles.closeButton}>
          <IonButton size='small' onClick={onClose} shape='round'>
            <IonIcon icon={closeOutline} slot='icon-only' size='small' />
          </IonButton>
        </div>

        <div className={styles.infoToast_Container}>
          <IonRow className='ion-align-items-center'>
            <IonCol size='auto'>
              <Icon name='info' color={succesColor} />
            </IonCol>
            <IonCol>
              <IonText className={styles.title}>Tip</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText className={styles.message}>{message}</IonText>
            </IonCol>
          </IonRow>
        </div>
      </div>
    )
  );
};

/** Report detail page, holding tabs for analysis, kinematics, and overview */
import { IonContent, IonGrid, IonPage } from '@ionic/react';
import { Suspense, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

// Styles
import styles from '../Styles/ReportDetailPage.module.css';
import { mergeClassNames } from '../../../utilities/mergeClassNames';

// Data
import { ReportInfoId } from '../../../model';
import { SquatReportData } from '../../../model/reportV2.model';

// Hooks & context
import { useReportContext } from '../../../components/reportContext';
import { createSquatReportData } from '../../../hooks/useReports';

// Utils
import { getTitleAndDescriptionFromReportData } from '../../../utilities/getTitleAndDescriptionFromReportData';

// Components
import { Loading } from '../../../components/Loading/Loading';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { PageTopBar } from '../../../components/PageTopBar/PageTopBar';
import { SegmentButton, SegmentedControl } from '../../../components/SegmentedControl';
import { OverviewReportTab } from '../OverviewReportTab';
import { SquatAnalysisReportTab } from './SquatsAnalysisReportTab';
import { SquatsKinematicsReportTab } from './SquatsKinematicsReportTab';
import { SquatsVariabilityReportTab } from './SquatsVariabilityReportTab';
import { FloatingButton } from '../../../components/FloatingButton/FloatingButton';

interface ParamTypes {
  id: ReportInfoId;
}
/** Report detail page
 * Loading the report data for the given userId and reportInfoId in the url.
 * Switch the tabs from Walk to Run depending on reportType in the loaded report data.
 * @param reportInfoId - the reportInfoId from the url
 */
export const SquatReportDetailPageV2 = () => {
  const location = useLocation<{ title: string; description: string }>();
  const { id } = useParams<ParamTypes>();
  const { getReportData, dataReady, data, setDataReady, resetData } = useReportContext();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('analysis');
  const [squatReportData, setSquatReportData] = useState<SquatReportData | undefined>();

  const history = useHistory();

  const { title, description } = location.state || getTitleAndDescriptionFromReportData(squatReportData);

  useEffect(() => {
    getReportData(id);
    // Cleanup the data when the component unmounts
    return () => {
      setLoading(true);
      setSquatReportData(undefined);
      setDataReady(false); // Reset dataReady in the context
      resetData();
    };
  }, [id]);

  useEffect(() => {
    if (data) {
      setSquatReportData(createSquatReportData(data));
    } else {
      setSquatReportData(undefined);
    }
  }, [dataReady]);

  useEffect(() => {
    if (squatReportData !== undefined && data) setLoading(false);
  }, [squatReportData]);

  useEffect(() => {
    if (import.meta.env.MODE !== 'production') console.log('reportData', squatReportData);
  }, [squatReportData]);

  const handleOnSegmentClick = (target: string) => {
    setActiveTab(target);
    history.push(`/reportsV2/Squat/${id}/${target}`);
  };

  const segments: SegmentButton[] = [
    { title: 'Analysis', value: 'analysis', onClick: () => handleOnSegmentClick('analysis') },
    { title: 'Variability', value: 'variability', onClick: () => handleOnSegmentClick('variability') },
    { title: 'Kinematics', value: 'kinematics', onClick: () => handleOnSegmentClick('kinematics') },
    { title: 'Overview', value: 'overview', onClick: () => handleOnSegmentClick('overview') },
  ];

  return (
    <IonPage>
      <PageHeader
        isLoading={loading}
        topSlot={<PageTopBar backTarget='/reports' title={title} description={description} variant='simple' />}
      >
        <SegmentedControl variant='default' value={activeTab} segments={segments} />
      </PageHeader>
      <div className={styles.background}>
        <IonContent className={mergeClassNames('ion-padding', styles.content)} id='main' color='light'>
          <IonGrid fixed style={{ height: '100%' }}>
            {loading && <Loading />}

            {squatReportData && (
              <>
                <Suspense fallback={<Loading />}>
                  {activeTab === 'analysis' && <SquatAnalysisReportTab tabData={squatReportData.squatAnalysisData} />}
                  {activeTab === 'variability' && (
                    <SquatsVariabilityReportTab tabData={squatReportData.squatVariabilityData} />
                  )}
                  {activeTab === 'kinematics' && (
                    <SquatsKinematicsReportTab tabData={squatReportData.squatKinematicData} />
                  )}
                  {activeTab === 'overview' && (
                    <OverviewReportTab
                      fileInfo={squatReportData.fileInfo}
                      reportType={squatReportData.reportType}
                      id={id}
                    />
                  )}
                </Suspense>
              </>
            )}
          </IonGrid>
        </IonContent>
      </div>
    </IonPage>
  );
};

import { PropsWithChildren } from 'react';
import { IonInput } from '@ionic/react';
import { JSX as IonicJSX } from '@ionic/core';
import { Icon, IconName } from '../Icon';
import { IconRotate, IconSize } from '../Icon/types';

import styles from './index.module.css';
import { mergeClassNames } from '../../utilities/mergeClassNames';

type IonicInputProps = IonicJSX.IonInput;

interface InputProps extends IonicInputProps {
  leadingIcon?: IconName;
  leadingIconDirection?: IconRotate;
  trailingIcon?: IconName;
  trailingIconDirection?: IconRotate;
  /** Should the input be shown in an error state */
  hasError?: boolean;
  className?: string;
  variant?: 'primary' | 'secondary' | 'light';
}

export const Input = ({
  leadingIcon,
  leadingIconDirection,
  trailingIcon,
  trailingIconDirection,
  hasError,
  className,
  children,
  variant = 'primary',
  ...props
}: PropsWithChildren<InputProps>) => {
  return (
    <IonInput
      className={mergeClassNames(
        styles.input,
        hasError && styles.error,
        className,
        leadingIcon && styles['has-leading-icon'],
        trailingIcon && styles['has-trailing-icon'],
        styles[variant],
      )}
      {...props}
    >
      {leadingIcon && (
        <Icon
          name={leadingIcon}
          direction={leadingIconDirection}
          size={IconSize.small}
          className={mergeClassNames(styles.icon, styles['icon-left'], styles[variant])}
        />
      )}

      {children}

      {trailingIcon && (
        <Icon
          name={trailingIcon}
          className={mergeClassNames(styles.icon, styles['icon-right'])}
          direction={trailingIconDirection}
          size={IconSize.small}
        />
      )}
    </IonInput>
  );
};

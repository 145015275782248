/* ORYX App 2.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * Login form Component, including the logo.
 */

import { InputCustomEvent, IonCol, IonGrid, IonImg, IonList, IonRow, IonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';

import logo from '../../assets/oryx-logo.svg';
import { Button } from '../Button';
import { Input } from '../Input';
import { mergeClassNames } from '../../utilities/mergeClassNames';
import { z } from 'zod';

import styles from './loginForm.module.css';
import { Toast } from '../Toast/Toast';
import { useHistory } from 'react-router';
import { useAuthContext } from '../authContext';
import { firebaseErrorToUserError } from '../../utilities/firebaseErrorToUserError';

export const NewPasswordForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [codeError, setCodeError] = useState<string | null>(null);
  const [isCodeValid, setIsCodeValid] = useState<boolean>(true);
  const [hasInputErrorToast, setHasInputErrorToast] = useState<boolean>(false);
  const [hasChangeError, setHasChangeError] = useState<boolean>(false);
  const [isValidPassword, setIsValidPassword] = useState<boolean>(true);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [passwordResetCode, setPasswordResetCode] = useState<string>('');
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setShowPassword(!showPassword);
  };

  const { confirmPasswordReset, verifyPasswordResetCode } = useAuthContext();

  useEffect(() => {
    //getting the OOB code from the URL params, this oob code is sent in an email (embedded in a link)
    const urlParams = new URLSearchParams(window.location.search);

    try {
      const newResetCode = urlParams.get('oobCode')?.toString();
      setPasswordResetCode(newResetCode ?? '');
    } catch {
      setPasswordResetCode('');
    }
  }, []);

  useEffect(() => {
    async function validatePasswordResetCode() {
      const [isValid, errorMessage] = await verifyPasswordResetCode(passwordResetCode);

      if (isValid) {
        setIsCodeValid(true);
        setCodeError(null);
      } else {
        setIsCodeValid(false);
        setCodeError(errorMessage);
      }
    }

    if (passwordResetCode.length > 0) {
      validatePasswordResetCode();
    }
  }, [passwordResetCode]);

  async function submitNewPassword(e: React.FormEvent) {
    e.preventDefault();
    setHasChangeError(false);
    setFormSubmitted(true);

    if (password && (await confirmPasswordReset(passwordResetCode, password))) {
      history.push(`/?passwordChanged=true`);
    } else {
      setHasChangeError(true);
    }
  }

  const handlePassword = (e: any) => {
    const passwordInput = e.detail.value;
    setPassword(passwordInput);
  };

  const minPasswordLength = 8;

  // checks all validations are true

  const validatePassword = (event: InputCustomEvent<FocusEvent>) => {
    const newPassword = event.target.value?.toString() ?? '';

    const passwordValidationSchema = z
      .string()
      .min(minPasswordLength, { message: `Password must be at least ${minPasswordLength} characters long` })
      .regex(/[A-Z]/, { message: 'Password must contain at least one uppercase letter' })
      .regex(/[a-z]/, { message: 'Password must contain at least one lowercase letter' })
      .regex(/\d/, { message: 'Password must contain at least one number' })
      .regex(/[~`!@#$%^&*+=\-[\]\\';,/{}|\\"():<>?]/, {
        message: 'Password must contain at least one special character',
      });

    const result = passwordValidationSchema.safeParse(newPassword);

    if (result.success) {
      setIsValidPassword(true);
      setHasInputErrorToast(false);
      setPasswordError(null);
    } else {
      setIsValidPassword(false);
      setHasInputErrorToast(true);
      setPasswordError(result.error.errors[0].message);

      setTimeout(() => {
        setHasInputErrorToast(false);
      }, 6500);
    }
  };

  return (
    <>
      <Toast
        isOpen={!isCodeValid}
        header='Reset link is no longer valid'
        message={firebaseErrorToUserError(codeError ?? '')}
        onDidDismiss={() => {
          setIsCodeValid(true);
          setCodeError(null);
        }}
        type={'warning'}
      />

      <Toast
        isOpen={hasInputErrorToast}
        header="Password doesn't meet requirements"
        message={passwordError ?? "The password doesn't meet the password requirements"}
        type={'warning'}
        duration={6000}
      />

      <Toast
        isOpen={hasChangeError}
        header='Error changing password'
        message={firebaseErrorToUserError('auth/generic-change-error')}
        type='warning'
      />

      <form noValidate onSubmit={submitNewPassword} className={styles['login-form']}>
        <IonGrid fixed className={styles['no-padding']} id='login-form'>
          <div>
            {import.meta.env.MODE !== 'production' && (
              <>
                <IonRow className={mergeClassNames('warning', styles.dev)}>
                  <IonCol>
                    <IonText style={{ color: '#ffffff' }}>{import.meta.env.MODE} environment!</IonText>
                  </IonCol>
                </IonRow>
              </>
            )}

            <IonRow>
              <IonCol size='12' className={styles['no-padding']}>
                <IonImg src={logo} className={styles.logo}></IonImg>
              </IonCol>
            </IonRow>
          </div>
          <div id='focus-target' className={styles.main}>
            <IonRow>
              <IonCol size='12' className={styles.copy}>
                <IonText className={styles.heading}>Set a new password</IonText>
                <IonText className={mergeClassNames(styles.description, styles.relaxed)}>
                  Create a strong password for your account. Once updated, use this password to log in securely.
                </IonText>
              </IonCol>
            </IonRow>

            <IonRow className={styles['no-padding']}>
              <IonCol size='12' class={styles['no-padding']}>
                <IonList mode='md' class={mergeClassNames(styles['no-padding'], styles.list)}>
                  <Input
                    name='password'
                    type='password'
                    label='New password'
                    clearOnEdit={false}
                    autocomplete='new-password'
                    label-placement='stacked'
                    onIonInput={handlePassword}
                    onIonBlur={validatePassword}
                    hasError={!isValidPassword}
                  />
                </IonList>
              </IonCol>
            </IonRow>

            <IonRow class={styles['no-padding']}>
              <IonCol size='12' class={mergeClassNames(styles['button-wrapper'], styles['no-padding'])}>
                <Button type='submit' isFullWidth variant='primary' className={styles.button} isLoading={isLoading}>
                  Change password
                </Button>
              </IonCol>
            </IonRow>
          </div>
        </IonGrid>
      </form>
    </>
  );
};

/** Walk analysis report tab */
import { IonCardContent, IonCol, IonRow, IonText } from '@ionic/react';

import { useEffect } from 'react';

import { Card } from '../../../components/Card';
import { CardHeader } from '../../../components/CardHeader/cardHeader';
import { JointCouplingData } from '../../../model/reportV2.model';
import { WalkJointCouplingGraph } from '../components/Graphs';
import styles from '../Styles/Reports.module.css';

type WalkJointCouplingReportProps = {
  tabData: JointCouplingData;
};

/**
 * Walk Joint Coupling Report Tab
 * @param report - The walk report data
 */
export const WalkJointCouplingReportTab = ({ tabData }: WalkJointCouplingReportProps) => {
  useEffect(() => {
    console.log('JointCouplingData', tabData.seriesJointCoupling_Left);
  }, [tabData]);

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <IonRow className={styles.jointCouplingRow}>
          <Card style={{ height: '100%', marginLeft: 0, marginRight: 0 }}>
            <CardHeader title='Hip / Kee Joint Coupling' />
            <IonCardContent
              className=''
              style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', height: '92.5%' }}
            >
              <IonRow className='ion-justify-content-center ion-align-items-center' style={{ height: '100%' }}>
                <IonCol size='6' style={{ height: '75%' }}>
                  <WalkJointCouplingGraph
                    data={tabData.seriesJointCoupling_Left}
                    side={'Left'}
                    xMin={tabData.jointCouplingAxisRange.xMinValue}
                    xMax={tabData.jointCouplingAxisRange.xMaxValue}
                    xRange={tabData.jointCouplingAxisRange.xTickAmount}
                  />
                </IonCol>
                <IonCol size='6' style={{ height: '75%' }}>
                  <WalkJointCouplingGraph
                    data={tabData.seriesJointCoupling_Right}
                    side={'Right'}
                    xMin={tabData.jointCouplingAxisRange.xMinValue}
                    xMax={tabData.jointCouplingAxisRange.xMaxValue}
                    xRange={tabData.jointCouplingAxisRange.xTickAmount}
                  />
                </IonCol>
              </IonRow>
            </IonCardContent>
          </Card>
        </IonRow>
      </>
    );
  }
};

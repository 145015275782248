/** Context provider for user authentication */
import { Preferences } from '@capacitor/preferences';
import React from 'react';
import { VerifyPasswordResetCodeResponse, useAuth } from '../../hooks/useAuth';
import { UserRole } from '../../model/user.model';
import { AuthState, setAuthed, setError, setLoading, setUnAuthed, useAuthReducer } from '../../store/auth.store';
import { CACHE_KEYS } from '../../utilities';

type AuthContextProps =
  | {
      state: AuthState;
      isAuthorized: () => boolean;
      signUp: (email: string, password: string, displayName: string) => void;
      signInWithEmailAndPassword: (email: string, password: string) => void;
      signInWithGoogle: () => void;
      signInWithFacebook: () => void;
      signInWithTwitter: () => void;
      signOut: () => void;
      resetPassword: (email: string) => void;
      confirmPasswordReset: (confirmationCode: string, newPassword: string) => Promise<boolean>;
      clearError: () => void;
      verifyPasswordResetCode: (passwordResetCode: string) => Promise<VerifyPasswordResetCodeResponse>;
    }
  | undefined;
const authContext = React.createContext<AuthContextProps>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}
export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authState, dispatch] = useAuthReducer();

  React.useEffect(() => {
    useAuth.onAuthChanged(async (user) => {
      if (user) {
        const role = await useAuth.getRole();
        dispatch(setAuthed({ user, role }));
      } else {
        dispatch(setUnAuthed());
      }
    });
  }, [dispatch]);

  function isAuthorized(): boolean {
    const role = authState.userState?.role;
    return !!(role && (role === UserRole.ADMIN || role === UserRole.CLIENT || role === UserRole.ORYXADMIN));
  }

  // Wrap sign-up and sign-in with setting loading and error states
  const tryTo = async <T,>(operation: () => Promise<T>): Promise<T> => {
    try {
      dispatch(setLoading());
      const result = await operation();
      return result;
    } catch (error: any) {
      dispatch(setError(error.message));
      return error;
    }
  };

  function signUp(email: string, password: string, displayName: string) {
    tryTo(async () => {
      await useAuth.createUserWithEmailAndPassword(email, password);
      const user = useAuth.currentUser();
      await user?.sendEmailVerification();
      user?.updateProfile({ displayName });
    });
  }

  function signInWithEmailAndPassword(email: string, password: string) {
    return tryTo(async () => useAuth.signInWithEmailAndPassword(email, password));
  }

  function signInWithGoogle() {
    return tryTo(useAuth.signInWithGoogle);
  }

  function signInWithFacebook() {
    return tryTo(useAuth.signInWithFacebook);
  }

  function signInWithTwitter() {
    return tryTo(useAuth.signInWithTwitter);
  }

  function signOut() {
    return tryTo(useAuth.signOut).then(() => {
      // Clear local storage on sign-out.
      Preferences.remove({ key: CACHE_KEYS.USERINFO_CACHE_KEY }).then(() => {
        if (import.meta.env.MODE !== 'production') {
          console.log('Local storage cleared.');
        }
      });
    });
  }

  function resetPassword(email: string) {
    return tryTo(async () => useAuth.resetPassword(email));
  }

  function confirmPasswordReset(confirmationCode: string, newPassword: string): Promise<boolean> {
    return tryTo(async () =>
      useAuth.confirmPasswordReset(confirmationCode, newPassword).then((result) => {
        return result;
      }),
    );
  }

  function verifyPasswordResetCode(passwordResetCode: string): Promise<VerifyPasswordResetCodeResponse> {
    if (!passwordResetCode || typeof passwordResetCode !== 'string') {
      return Promise.reject(new Error('auth/invalid-action-code'));
    }

    return tryTo(async () => useAuth.validatePasswordResetCode(passwordResetCode));
  }

  function clearError() {
    dispatch(setError(''));
  }

  const value: AuthContextProps = {
    state: authState,
    isAuthorized,
    signUp,
    signInWithEmailAndPassword,
    signInWithGoogle,
    signInWithFacebook,
    signInWithTwitter,
    signOut,
    resetPassword,
    confirmPasswordReset,
    clearError,
    verifyPasswordResetCode,
  };

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};

export function useAuthContext() {
  const context = React.useContext(authContext);

  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }

  return context;
}

import { IonCol, IonGrid, IonRow } from '@ionic/react';
// Slides
import { SwiperSlide } from 'swiper/react';
import { VerticalSwiper } from '../../../../components/swiper';

// Styles
import { RearPendulumModalGraph } from '../Graphs';

interface ModalProps {
  data: any;
}

export const RearPendulumModalContent = ({ data }: ModalProps) => {
  return (
    <>
      <VerticalSwiper>
        <SwiperSlide>
          <IonGrid className='' style={{ height: '100%' }}>
            <IonRow className='ion-align-items-center' style={{ height: '100%' }}>
              <IonCol size='6' className='' style={{ height: '75%' }}>
                <IonRow style={{ height: '75%' }} className=''>
                  <RearPendulumModalGraph
                    side={'Left'}
                    data={data.rearPendulumGraphData_Left}
                    TOLocation={data.rearPendulum_ToeOff_Left}
                    MSwLocation={data.rearPendulum_MidSwing_Left}
                    stanceData={data.rearPendulumStance_Left}
                  />
                </IonRow>
                <IonRow className=''>
                  <IonCol size='6' className='ion-no-padding border_Right ion-text-center'>
                    <p> &sigma; TO: {data.rearPendulumVariance_ToeOff_Left}</p>
                  </IonCol>
                  <IonCol size='6' className='ion-no-padding ion-text-center'>
                    <p> &sigma; MSw: {data.rearPendulumVariance_MidSwing_Left}</p>
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol size='6' style={{ height: '75%' }} className='ion-align-items-center'>
                <IonRow style={{ height: '75%' }} className=''>
                  <RearPendulumModalGraph
                    side={'Right'}
                    data={data.rearPendulumGraphData_Right}
                    TOLocation={data.rearPendulum_ToeOff_Right}
                    MSwLocation={data.rearPendulum_MidSwing_Right}
                    stanceData={data.rearPendulumStance_Right}
                  />
                </IonRow>
                <IonRow className=''>
                  <IonCol size='6' className='ion-no-padding border_Right ion-text-center'>
                    <p> &sigma; TO: {data.rearPendulumVariance_ToeOff_Right}</p>
                  </IonCol>
                  <IonCol size='6' className='ion-no-padding ion-text-center'>
                    <p> &sigma; MSw: {data.rearPendulumVariance_MidSwing_Right}</p>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
        </SwiperSlide>
      </VerticalSwiper>
    </>
  );
};

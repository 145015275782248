import { IonItem } from '@ionic/react';

// Styles
import styles from './NavItem.module.css';

// Components
import { Icon, type IconName } from '../Icon';

// Utils
import { mergeClassNames } from '../../utilities/mergeClassNames';

// Types
import type { ComponentProps, PropsWithChildren } from 'react';
import { IconRotate } from '../Icon/types';

interface NavItemProps extends ComponentProps<typeof IonItem> {
  isActive?: boolean;
  isUser?: boolean;
  icon?: IconName;
  isDanger?: boolean;
}

export const NavItem = ({ icon, children, isActive, isDanger, isUser, ...props }: PropsWithChildren<NavItemProps>) => (
  <IonItem
    className={mergeClassNames(
      styles.item,
      isActive && styles.active,
      isUser && styles.user,
      isDanger && styles.danger,
    )}
    lines='none'
    {...props}
    detail={false}
  >
    {icon && !isUser && <Icon className={styles.icon} name={icon} size={16} />}
    {isUser && (
      <svg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg' className={styles.userIcon}>
        <rect width='14' height='14' rx='4.2' fill='url(#a)' />
        <g filter='url(#b)'>
          <path
            d='M7 7.39c.932 0 1.78.27 2.403.65.311.19.577.413.77.657.188.239.327.525.327.831 0 .329-.16.588-.39.772-.217.175-.505.291-.81.372-.614.162-1.433.217-2.3.217-.866 0-1.685-.054-2.3-.217-.304-.08-.592-.197-.81-.372-.23-.185-.39-.443-.39-.772 0-.306.14-.592.329-.832.192-.243.457-.466.769-.657A4.69 4.69 0 0 1 7 7.39Zm0-4.278A1.944 1.944 0 1 1 7 7a1.944 1.944 0 0 1 0-3.888Z'
            fill='url(#c)'
          />
        </g>
        <defs>
          <linearGradient id='a' x1='7' y1='0' x2='7' y2='14' gradientUnits='userSpaceOnUse'>
            <stop stopColor='#C8ED9B' />
            <stop offset='1' stopColor='#90C953' />
          </linearGradient>
          <linearGradient id='c' x1='7' y1='9.1' x2='7' y2='10.889' gradientUnits='userSpaceOnUse'>
            <stop stopColor='#fff' />
            <stop offset='1' stopColor='#F1FAE5' />
          </linearGradient>
          <filter
            id='b'
            x='2.8'
            y='3.112'
            width='8.4'
            height='9.178'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
            <feOffset dy='.7' />
            <feGaussianBlur stdDeviation='.35' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix values='0 0 0 0 0.0392157 0 0 0 0 0.180392 0 0 0 0 0.172549 0 0 0 0.1 0' />
            <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_2304_746' />
            <feBlend in='SourceGraphic' in2='effect1_dropShadow_2304_746' result='shape' />
          </filter>
        </defs>
      </svg>
    )}
    {children}
    {props.detail && (
      <Icon
        name='chevron'
        direction={IconRotate.East}
        className={styles.chevron}
        color='rgba(77, 47, 0, 0.4)'
        size={14}
      />
    )}
  </IonItem>
);

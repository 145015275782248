/** The Run Kinematics report tab */
import { IonCardContent, IonCardTitle, IonCol, IonFabButton, IonGrid, IonIcon, IonRow, IonText } from '@ionic/react';

import { colorPalette } from 'ionicons/icons';
import { useEffect, useMemo, useState } from 'react';
import toggle from '../../../assets/icons/toggle-left.svg';
import { LegendToast } from '../../../components/LegendToast/LegendToast';
import { WalkKinematicData } from '../../../model/reportV2.model';
import { KinematicsGraphV2 } from '../components/Graphs';
import { Card } from '../../../components/Card';
import { KinematicsCardHeader } from '../../../components/CardHeader/KinematicsCardHeader';
import { CardHeader } from '../../../components/CardHeader/cardHeader';
import { DataTable } from '../../../components/DataTable/DataTable';
import { DataTableColumn } from '../../../components/DataTable/DataTableColumn';
import { DataTableHeader } from '../../../components/DataTable/DataTableHeader';
import { DataTableSection } from '../../../components/DataTable/DataTableSection';
import { FloatingButton } from '../../../components/FloatingButton/FloatingButton';
import { IconSize } from '../../../components/Icon/types';
import { InfoToast } from '../../../components/InfoToast/InfoToast';
import { Icon } from '../../../components/Icon';
import { mergeClassNames } from '../../../utilities/mergeClassNames';

// Styles
import styles from '../Styles/Reports.module.css';

type KinematicsReportProps = {
  tabData: WalkKinematicData;
  setFloatingButtons: (buttons: React.ReactNode) => void;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const WalkKinematicsReportTab = ({ tabData, setFloatingButtons }: KinematicsReportProps) => {
  const [showAllStrides, setShowAllStrides] = useState<boolean>(false);
  const [showToast, setShowToast] = useState(false);
  const [showInfoToast, setShowInfoToast] = useState(false);

  const handleShowToast = () => {
    setShowToast(true);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const romData = tabData.romData;
  const tableData = tabData.tableData;

  const toggleShowAllStrides = () => {
    setShowAllStrides((prev) => {
      const newValue = !prev;
      localStorage.setItem('showAllStridesKinematics', newValue ? 'true' : 'false');
      return newValue;
    });
  };

  const seriesPelvis = useMemo(() => {
    return showAllStrides ? tabData.pelvisKinematicData.allStrides : tabData.pelvisKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesHip = useMemo(() => {
    return showAllStrides ? tabData.hipKinematicData.allStrides : tabData.hipKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesKnee = useMemo(() => {
    return showAllStrides ? tabData.kneeKinematicData.allStrides : tabData.kneeKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesAnkle = useMemo(() => {
    return showAllStrides ? tabData.ankleKinematicData.allStrides : tabData.ankleKinematicData.mean;
  }, [showAllStrides, tabData]);

  useEffect(() => {
    setShowInfoToast(true);
    return () => {
      setShowInfoToast(false);
    };
  }, []);

  useEffect(() => {
    const buttons = (
      <>
        <IonFabButton onClick={() => toggleShowAllStrides()} title={'Toggle Mean / All Strides'}>
          <IonIcon icon={toggle}></IonIcon>
        </IonFabButton>
        <IonFabButton title={'Show Graph Legend'} onClick={handleShowToast}>
          <IonIcon icon={colorPalette}></IonIcon>
        </IonFabButton>
        <IonFabButton onClick={() => setShowInfoToast(true)} title={'Show Info'}>
          <Icon name={'info'}></Icon>
        </IonFabButton>
      </>
    );
    setFloatingButtons(buttons); // Update buttons in parent
    return () => setFloatingButtons(null); // Clean up on unmount
  }, [setFloatingButtons]);

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <IonRow className='ion-no-padding'>
          <IonCol size='12' className=''>
            <Card style={{ height: 'fit-content', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
              <KinematicsCardHeader />
              <IonCardContent>
                <IonRow style={{ borderBottom: '1px solid #ddd', marginBottom: '6px', backgroundColor: 'transparent' }}>
                  <IonGrid className='ion-no-padding'>
                    <IonRow className=''>
                      <IonCardTitle className={styles.title}>Pelvis</IonCardTitle>
                    </IonRow>
                    <IonRow className='ion-no-padding'>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesPelvis.Sagittal.series}
                          subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Sagittal.yAxis}
                          title={'Pelvis - Sagittal'}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesPelvis.Coronal.series}
                          subtitle={'Drop (-) / Hike (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Coronal.yAxis}
                          title={'Pelvis - Frontal'}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesPelvis.Transversal.series}
                          subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesPelvis.Transversal.yAxis}
                          title={'Pelvis - Transversal'}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonRow>

                <IonRow style={{ borderBottom: '1px solid #ddd', marginBottom: '6px' }}>
                  <IonGrid className='ion-no-padding'>
                    <IonRow className=''>
                      <IonCardTitle className={styles.title}>Hip</IonCardTitle>
                    </IonRow>
                    <IonRow className='ion-no-padding'>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesHip.Sagittal.series}
                          subtitle={'Extension (-) / Flexion (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Sagittal.yAxis}
                          title={'Hip - Sagittal'}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesHip.Coronal.series}
                          subtitle={'Abduction (-) / Adduction (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Coronal.yAxis}
                          title={'Hip - Frontal'}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesHip.Transversal.series}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesHip.Transversal.yAxis}
                          title={'Hip - Transversal'}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonRow>

                <IonRow style={{ borderBottom: '1px solid #ddd', marginBottom: '6px' }}>
                  <IonGrid className='ion-no-padding'>
                    <IonRow className=''>
                      <IonCardTitle className={styles.title}>Knee</IonCardTitle>
                    </IonRow>
                    <IonRow className='ion-no-padding'>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesKnee.Sagittal.series}
                          subtitle={'Extension (-) / Flexion (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Sagittal.yAxis}
                          title={'Knee - Sagittal'}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesKnee.Coronal.series}
                          subtitle={'Valgus (-) / Varus (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Coronal.yAxis}
                          title={'Knee - Frontal'}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesKnee.Transversal.series}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesKnee.Transversal.yAxis}
                          title={'Knee - Transversal'}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonRow>

                <IonRow>
                  <IonGrid className='ion-no-padding'>
                    <IonRow className=''>
                      <IonCardTitle className={styles.title}>Ankle</IonCardTitle>
                    </IonRow>
                    <IonRow>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesAnkle.Sagittal.series}
                          subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Sagittal.yAxis}
                          title={'Ankle - Sagittal'}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesAnkle.Coronal.series}
                          subtitle={'Eversion (-) / Inversion (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Coronal.yAxis}
                          title={'Ankle - Frontal'}
                        />
                      </IonCol>
                      <IonCol size='4' className='ion-no-padding'>
                        <KinematicsGraphV2
                          data={seriesAnkle.Transversal.series}
                          subtitle={'External Rotation (-) / Internal Rotation (+)'}
                          graphAnnotations={tabData.graphAnnotations}
                          strokeWidth={showAllStrides ? 1 : 2}
                          yAxis={seriesAnkle.Transversal.yAxis}
                          title={'Ankle - Transversal'}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonRow>
              </IonCardContent>
            </Card>
          </IonCol>
        </IonRow>
        {/* Tables */}
        <IonRow class='ion-no-padding'>
          <IonCol size='6'>
            <Card style={{ margin: 0 }}>
              <CardHeader title={'Range of Motion'} />
              <IonCardContent>
                <IonGrid className='ion-no-padding'>
                  <IonRow
                    className={mergeClassNames(styles.full_height, 'ion-padding-vertical', 'ion-align-items-start')}>
                    <DataTable>
                      <DataTableHeader titles={['', 'Left', 'Right', 'Symmetry']} />
                      <DataTableSection>
                        <IonRow style={{ width: '100%' }}>
                          <DataTableColumn type='key'>Pelvis</DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Pelvis.Left}>
                            {romData[0][0].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Pelvis.Right}>
                            {romData[0][1].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Pelvis.Symmetry}>
                            {romData[0][2].toFixed(0) + '%'}
                          </DataTableColumn>
                        </IonRow>
                      </DataTableSection>
                      <DataTableSection>
                        <IonRow style={{ width: '100%' }}>
                          <DataTableColumn type='key'>Hip</DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Hip.Left}>
                            {romData[1][0].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Hip.Right}>
                            {romData[1][1].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Hip.Symmetry}>
                            {romData[1][2].toFixed(0) + '%'}
                          </DataTableColumn>
                        </IonRow>
                        <IonRow style={{ width: '100%' }}>
                          <DataTableColumn type='key' indent>
                            Flexion
                          </DataTableColumn>
                          <DataTableColumn type='value'>{romData[2][0].toFixed(1) + '°'}</DataTableColumn>
                          <DataTableColumn type='value'>{romData[2][1].toFixed(1) + '°'}</DataTableColumn>
                          <DataTableColumn type='value'></DataTableColumn>
                        </IonRow>
                        <IonRow style={{ width: '100%' }}>
                          <DataTableColumn type='key' indent>
                            Extension
                          </DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Hip.ExtensionLeft}>
                            {romData[3][0].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Hip.ExtensionRight}>
                            {romData[3][1].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value'></DataTableColumn>
                        </IonRow>
                      </DataTableSection>
                      <DataTableSection>
                        <IonRow style={{ width: '100%' }}>
                          <DataTableColumn type='key'>Knee</DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Knee.Left}>
                            {romData[4][0].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Knee.Right}>
                            {romData[4][1].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Knee.Symmetry}>
                            {romData[4][2].toFixed(0) + '%'}
                          </DataTableColumn>
                        </IonRow>
                        <IonRow style={{ width: '100%' }}>
                          <DataTableColumn type='key' indent>
                            Stance flexion
                          </DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Knee.stanceFlexionLeft}>
                            {romData[5][0].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Knee.stanceFlexionRight}>
                            {romData[5][1].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value'></DataTableColumn>
                        </IonRow>
                        <IonRow style={{ width: '100%' }}>
                          <DataTableColumn type='key' indent>
                            Varus Valgus
                          </DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Knee.varusValgusLeft}>
                            {romData[6][0].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Knee.varusValgusRight}>
                            {romData[6][1].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value'></DataTableColumn>
                        </IonRow>
                      </DataTableSection>
                      <DataTableSection>
                        <IonRow style={{ width: '100%' }}>
                          <DataTableColumn type='key'>Ankle</DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Ankle.Left}>
                            {romData[7][0].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Ankle.Right}>
                            {romData[7][1].toFixed(1) + '°'}
                          </DataTableColumn>
                          <DataTableColumn type='value' label={tabData.tableClassNames.Ankle.Symmetry}>
                            {romData[7][2].toFixed(0) + '%'}
                          </DataTableColumn>
                        </IonRow>
                        <IonRow style={{ width: '100%' }}>
                          <DataTableColumn type='key' indent>
                            Dorsal flexion
                          </DataTableColumn>
                          <DataTableColumn type='value'>{romData[8][0].toFixed(1) + '°'}</DataTableColumn>
                          <DataTableColumn type='value'>{romData[8][1].toFixed(1) + '°'}</DataTableColumn>
                          <DataTableColumn type='value'></DataTableColumn>
                        </IonRow>
                        <IonRow style={{ width: '100%' }}>
                          <DataTableColumn type='key' indent>
                            Plantar flexion
                          </DataTableColumn>
                          <DataTableColumn type='value'>{romData[9][0].toFixed(1) + '°'}</DataTableColumn>
                          <DataTableColumn type='value'>{romData[9][1].toFixed(1) + '°'}</DataTableColumn>
                          <DataTableColumn type='value'></DataTableColumn>
                        </IonRow>
                      </DataTableSection>
                    </DataTable>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </Card>
          </IonCol>

          <IonCol size='6'>
            <Card style={{ margin: 0, height: '100%' }}>
              <CardHeader title={'Spatio temporal parameters'} />
              <IonCardContent>
                <IonGrid className='ion-no-padding'>
                  <IonRow
                    className={mergeClassNames(styles.full_height, 'ion-padding-vertical', 'ion-align-items-start')}>
                    <DataTable>
                      <DataTableHeader titles={['', 'Left', 'Right']} />
                      <DataTableSection>
                        <IonRow style={{ width: '100%' }}>
                          <DataTableColumn type='key' subHeader={'(Percentage of stride time)'}>
                            Stance phase
                          </DataTableColumn>
                          <DataTableColumn type='value'>{tableData[3][1].toFixed(0) + ' %'}</DataTableColumn>
                          <DataTableColumn type='value'>{tableData[4][1].toFixed(0) + ' %'}</DataTableColumn>
                        </IonRow>
                        <IonRow style={{ width: '100%' }}>
                          <DataTableColumn type='key' subHeader={'(Seconds)'}>
                            Stance phase
                          </DataTableColumn>
                          <DataTableColumn type='value'>{tableData[0][1].toFixed(2) + ' s'}</DataTableColumn>
                          <DataTableColumn type='value'>{tableData[1][1].toFixed(2) + ' s'}</DataTableColumn>
                        </IonRow>
                      </DataTableSection>
                      <DataTableSection>
                        <IonRow style={{ width: '100%' }}>
                          <DataTableColumn type='key' subHeader={'(Percentage of stride time)'}>
                            Swing phase
                          </DataTableColumn>
                          <DataTableColumn type='value'>{tableData[3][2].toFixed(0) + ' %'}</DataTableColumn>
                          <DataTableColumn type='value'>{tableData[4][2].toFixed(0) + ' %'}</DataTableColumn>
                        </IonRow>
                        <IonRow style={{ width: '100%' }}>
                          <DataTableColumn type='key' subHeader={'(Seconds)'}>
                            Swing phase
                          </DataTableColumn>
                          <DataTableColumn type='value'>{tableData[0][2].toFixed(2) + ' s'}</DataTableColumn>
                          <DataTableColumn type='value'>{tableData[1][2].toFixed(2) + ' s'}</DataTableColumn>
                        </IonRow>
                      </DataTableSection>
                    </DataTable>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </Card>
          </IonCol>
        </IonRow>

        <FloatingButton
          slot='fixed'
          horizontal='end'
          vertical='bottom'
          side='top'
          buttonIcon='sliders'
          iconSize={IconSize.large}>
          <IonFabButton onClick={() => toggleShowAllStrides()} title={'Toggle Mean / All Strides'}>
            <IonIcon icon={toggle}></IonIcon>
          </IonFabButton>
          <IonFabButton title={'Show Graph Legend'} onClick={handleShowToast}>
            <IonIcon icon={colorPalette}></IonIcon>
          </IonFabButton>
          <IonFabButton onClick={() => setShowInfoToast(true)} title={'Show Info'}>
            <Icon name={'info'}></Icon>
          </IonFabButton>
        </FloatingButton>

        <LegendToast
          isOpen={showToast}
          onClose={handleCloseToast}
          duration={3000} // 3 seconds
        />

        <InfoToast
          isOpen={showInfoToast}
          onClose={() => setShowInfoToast(false)}
          message='All graphs can be enlarged by clicking / tapping on the graph.'
        />
      </>
    );
  }
};
